<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    :return-value.sync="date"
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="date"
        :label="label"
        :rules="rules"
        readonly
        v-on="on"
        clearable
        @click:clear="date = null"
        :disabled="disabled"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
      <v-btn text color="primary" @click="$refs.dialog.save(date)">OK</v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import { VInput } from "vuetify/lib/components/VInput";
import { dateZ } from "@/helpers";

export default {
  extends: VInput,
  props: {
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      modal: false,
    };
  },
  computed: {
    date: {
      get() {
        return this.toInternalFormat(this.value);
      },
      set(v) {
        this.$emit("input", this.toEmitFormat(v));
      },
    },
  },
  methods: {
    toInternalFormat(val) {
      if (val || "") {
        return dateZ.format(dateZ.parseISO(val), "yyyy-MM-dd");
      } else {
        return "";
      }
    },
    toEmitFormat(val) {
      if (val || "") {
        return dateZ.formatISO(dateZ.parseISO(`${val}T00:00`));
      } else {
        return "";
      }
    },
  },
};
</script>

<style></style>
