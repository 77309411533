<template>
  <DataExplorer
    v-model="selectedLocal"
    :headers="headers"
    :items="items"
    :search.sync="search"
    :loading="loading"
  >
    <template v-slot:item="props">
      <tr :key="`item-${props.item.id}`">
        <td>
          <v-checkbox
            :input-value="props.isSelected"
            @click="props.select(!props.isSelected)"
          ></v-checkbox>
        </td>
        <td>
          <v-btn
            class="btn-table"
            text
            small
            color="primary"
            @click="onItemSelect(props.item)"
            >{{ props.item.name }}</v-btn
          >
        </td>
        <td>{{ props.item.email }}</td>
        <td>{{ props.item.roles }}</td>
        <td>
          <active-dot v-model="props.item.active" />
        </td>
      </tr>
    </template>
  </DataExplorer>
</template>

<script>
import activeDot from "@/components/workspace/layout/active";
import DataExplorer from "../../layout/data-explorer.vue";

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    "active-dot": activeDot,
    DataExplorer,
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Roles", value: "roles" },
        { text: "Status", value: "active" },
      ],
    };
  },
  computed: {
    selectedLocal: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit("update:selected", val);
      },
    },
  },
  methods: {
    onItemSelect(item) {
      this.$emit("onItemSelect", item);
    },
  },
};
</script>

<style scoped>
.btn-table {
  padding: 0;
  margin: 0;
}
</style>
