<template>
  <v-layout row wrap>
    <v-flex v-if="loading" xs12>
      <v-progress-linear :indeterminate="true"></v-progress-linear>
    </v-flex>
    <v-flex v-if="empty">
      <div class="body-1 font-italic">Sorry, nothing to display here :(</div>
    </v-flex>
    <v-flex xs12>
      <v-expansion-panels accordion>
        <v-expansion-panel v-for="item in items" :key="item.id + 1">
          <v-expansion-panel-header color="grey lighten-4">
            <div>
              <div class="title">{{ item.name }}</div>
              <div class="body-1">
                <span> Profit: {{ currency(item.profit) }} </span>
                <span>
                  -- Vehicles: {{ item.vehicles }} -- Services:
                  {{ item.services }} -- Billed: {{ currency(item.billed) }} --
                  Cost: {{ currency(item.laborCost) }}
                </span>
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :mobile-breakpoint="0"
              :headers="headers"
              :items="mapItems(item.items)"
              hide-default-footer
              disable-pagination
            >
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import { reportService } from "@/services";
import { isEmpty } from "@/helpers";
import { currency } from "@/helpers";
import { dateUS } from "@/filters/index";

export default {
  name: "profitability",
  data() {
    return {
      loading: false,
      items: [],
      headers: [
        { text: "Date", value: "upChargeDate", sortable: false },
        { text: "VIN", value: "vin", sortable: false },
        { text: "Number", value: "stockNumber", sortable: false },
        { text: "Vehicle", value: "vehicle", sortable: false },
        { text: "Qty", value: "quantity", sortable: false },
        { text: "Service", value: "name", sortable: false },
        { text: "Cost", value: "upChargeCost", sortable: false },
        { text: "Billed", value: "upChargePrice", sortable: false },
      ],
    };
  },
  async created() {
    await this.refresh();
  },
  computed: {
    empty() {
      return isEmpty(this.items) && !this.loading;
    },
    ...mapGetters("context", { filterDateRange: "filterDateRangeForServer" }),
  },
  watch: {
    async filterDateRange() {
      await this.refresh();
    },
  },
  methods: {
    currency,
    dateUS,
    async refresh() {
      this.loading = true;
      try {
        await this.fetchItems();
      } catch (error) {
        this.$error(error);
      }
      this.loading = false;
    },
    async fetchItems() {
      this.items = await reportService.get(
        "profitability",
        this.filterDateRange
      );
    },
    mapItems(items) {
      return items.map((x) => ({
        upChargeDate: this.dateUS(x.upChargeDate),
        vin: x.vin,
        stockNumber: x.stockNumber,
        vehicle: x.description,
        quantity: x.quantity,
        name: x.name,
        upChargeCost: this.currency(x.upChargeCost),
        upChargePrice: this.currency(x.upChargePrice),
      }));
    },
  },
};
</script>

<style></style>
