
import { defineComponent } from "vue";
import { useConfirm } from "@/composables/useConfirm";

export default defineComponent({
  name: "ConfirmInline",
  props: {
    labelOk: { type: String, default: "Ok" },
    labelCancel: { type: String, default: "Cancel" },
  },
  setup() {
    const { open: dialog, message, title, cancel, ok } = useConfirm();

    return {
      dialog,
      title,
      message,
      ok,
      cancel,
    };
  },
});
