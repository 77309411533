const toast = {
  show: false,
  install(Vue, options) {
    if (this.installed || false) {
      return;
    }
    this.installed = true;
    Vue.prototype.$toast = function (message) {
      let store = options.store;
      store.commit("alert/success", message);
    };
    Vue.prototype.$error = function (message) {
      let store = options.store;
      // eslint-disable-next-line no-console
      console.error(message);
      store.commit("alert/error", message);
    };
  },
};

export default toast;
