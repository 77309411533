import { config } from "@/config";
import { authenticationService } from "./authentication.service";
let { authHeader, handleResponse } = authenticationService;

export const invoiceService = {
  all,
  get,
  save,
  tag,
  createWithServices,
};

async function all(scope, range) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(
    `${config.api.base}/invoices/scope/${scope}/${range}`,
    requestOptions
  );
  return await handleResponse(response);
}

async function get(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(
    `${config.api.base}/invoices/${id}`,
    requestOptions
  );
  return await handleResponse(response);
}

async function save(invoice) {
  let contentType = { "Content-Type": "application/json" };
  const requestOptions = {
    method: "PUT",
    headers: { ...contentType, ...authHeader() },
    body: JSON.stringify(invoice),
  };

  let response = await fetch(
    `${config.api.base}/invoices/${invoice.id}`,
    requestOptions
  );
  return await handleResponse(response);
}

async function createWithServices(serviceIds) {
  let contentType = { "Content-Type": "application/json" };
  const requestOptions = {
    method: "POST",
    headers: { ...contentType, ...authHeader() },
    body: JSON.stringify(serviceIds),
  };

  let response = await fetch(
    `${config.api.base}/invoices/services/new`,
    requestOptions
  );
  return await handleResponse(response);
}

async function tag(tagType, ids) {
  let contentType = { "Content-Type": "application/json" };
  const requestOptions = {
    method: "POST",
    headers: { ...contentType, ...authHeader() },
    body: JSON.stringify(ids),
  };
  let response = await fetch(
    `${config.api.base}/stocks/tag/${tagType}`,
    requestOptions
  );
  return await handleResponse(response);
}
