
import { PropType, computed, defineComponent, ref, watch } from "vue";
import { roles } from "@/store/data";
import useContext from "@/composables/useContext";

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object as PropType<{
        name: string;
        nameInitials: string;
        email: string;
        roles: string;
        active: boolean;
        workLogConfirmation: boolean;
      }>,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const { isPhone } = useContext();

    const dialog = computed({
      get() {
        return props.value;
      },
      set(val) {
        emit("input", val);
      },
    });

    const valid = ref(false);
    const rolesSelected = ref(["none"]);
    const rolesData = ref(roles);
    const model = ref({
      name: "",
      nameInitials: "",
      email: "",
      roles: "",
      active: true,
      workLogConfirmation: false,
    });
    const emailRules = [
      (v: string) => !!v || "E-mail is required",
      (v: string) => /.+@.+/.test(v) || "E-mail must be valid",
    ];

    const vSplit = (s: string) => {
      if ((s || "") === "") {
        return ["none"];
      } else {
        return s.split(",");
      }
    };

    const vJoin = (a: string[]) => {
      if (a && a.length) {
        return a.join();
      } else {
        return null;
      }
    };

    const isValidRoles = computed(() => {
      let roles = rolesSelected.value.filter((x) => x !== "none");
      return roles.length >= 1;
    });

    const isValid = () => {
      return valid.value && isValidRoles.value;
    };

    const notify = () => {
      save(true);
    };

    const ok = () => {
      save(false);
    };

    const save = (notify: boolean) => {
      if (isValid()) {
        dialog.value = false;
        let roles = rolesSelected.value.filter((x) => x !== "none");
        model.value.roles = vJoin(roles) || "";
        emit("onSave", model.value, notify ? true : false);
      }
    };

    const cancel = () => {
      dialog.value = false;
      emit("onCancel");
    };

    watch(
      () => props.item,
      (val) => {
        model.value = val;
        rolesSelected.value = vSplit(val.roles);
      }
    );

    return {
      dialog,
      valid,
      rolesSelected,
      rolesData,
      model,
      emailRules,
      isValidRoles,
      isValid,
      notify,
      ok,
      emit,
      cancel,
      isPhone,
    };
  },
});
