//bool ok = await root.$confirm.show(title,note);
//bool ok = root.$confirm.cancel();
//bool ok = root.$confirm.isShow();

import PromiseVuetifyConfirm from "./confirm-component";

let plugin = function (Vue, options) {
  this.Vue = Vue;
  this.mounted = false;
  this.$root = {};
  this.options = options;
  if (options.context) {
    this.context = options.context;
  }
};

plugin.prototype.mountIfNotMounted = function () {
  if (this.mounted === true) {
    return;
  }
  //thanks to https://github.com/c4software/vuetify-vuejs-confirmDialog
  this.$root = (() => {
    let ConfirmConstructor = this.Vue.extend(PromiseVuetifyConfirm);
    return new ConfirmConstructor(this.context).$mount();
    //let node = document.createElement("div");
    //document.querySelector("#app").appendChild(node);
    //return new ConfirmConstructor(this.context).$mount(node)
  })();
  this.mounted = true;
};

plugin.prototype.show = function (title, text) {
  this.mountIfNotMounted();
  return this.$root.show(title, text);
};

plugin.prototype.cancel = function () {
  if (this.mounted) {
    this.$root.cancel();
  }
};

plugin.prototype.isShow = function () {
  if (this.mounted) {
    return this.$root.isShow();
  } else {
    return false;
  }
};

plugin.install = function (Vue, options) {
  let dialogConfirm = new plugin(Vue, options);
  dialogConfirm.mountIfNotMounted();
  Object.defineProperties(Vue.prototype, {
    $confirm: {
      get() {
        return dialogConfirm;
      },
    },
  });
};

export default plugin;
