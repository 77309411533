<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{}">
      <v-btn
        @click="dialog = true"
        color="accent"
        title="Edit Properties of selected items"
      >
        <v-icon left>mdi-square-edit-outline</v-icon>
        Edit ...
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark class="blue darken-3" dense>
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title
          >Vehicles Services -- ({{ this.items.length }} items
          selected)</v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6>
                <date-picker
                  :disabled="!serviceDateSwitch"
                  v-model="serviceDate"
                  label="Services Date"
                  :rules="serviceDateRules()"
                />
                <v-switch v-model="serviceDateSwitch"></v-switch>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" color="blue darken-1" text @click="close()"
            >Close</v-btn
          >
          <v-btn
            :disabled="saveButtonDisable()"
            :loading="loading"
            color="blue darken-1"
            text
            @click="save()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import datePicker from "../layout/date-picker";
import { worklogService } from "@/services";

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    "date-picker": datePicker,
  },
  data: () => ({
    serviceDate: null,
    serviceDateSwitch: false,
    loading: false,
    valid: true,
  }),
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    serviceDateSwitch() {
      this.validReset();
    },
  },
  methods: {
    serviceDateRules() {
      if (this.serviceDateSwitch) return [(v) => !!v || "Required"];
      else return [];
    },
    isValid() {
      return this.$refs.form.validate();
    },
    validReset() {
      this.$refs.form.resetValidation();
    },
    saveButtonDisable() {
      return !this.serviceDateSwitch;
    },
    async save() {
      this.loading = true;
      try {
        if (this.isValid()) {
          await this.serviceDatePost();
          this.close();
          this.$emit("onRefresh");
        }
      } catch (err) {
        this.$error(err);
      }
      this.loading = false;
    },
    close() {
      this.formReset();
      this.dialog = false;
    },
    formReset() {
      this.serviceDate = null;
      this.dialog = false;
      this.serviceDateSwitch = false;
    },
    async serviceDatePost() {
      if (this.serviceDateSwitch) {
        let itemIds = this.items;
        let propertyType = "serviceDate";
        let propertyValue = this.serviceDate;
        await worklogService.batchSave(propertyType, propertyValue, itemIds);
      }
    },
  },
};
</script>

<style></style>
