import Vue from "vue";
import Router from "vue-router";

import Login from "@/components/auth/login.vue";
import AuthActivate from "@/components/auth/activate.vue";
import Workspace from "@/components/workspace/layout/master/index.vue";
import Dashboard from "@/components/workspace/dashboard/index.vue";

import Batchs from "@/components/workspace/batchs/index.vue";
import BatchPreview from "@/components/workspace/batchs/preview.vue";

import Stock from "@/components/workspace/stock/index.vue";
import SearchVin from "@/components/workspace/search/index.vue";

import Worklog from "@/components/workspace/worklog/index.vue";

//import Invoices from "@/components/workspace/invoices";
//import InvoiceEdit from "@/components/workspace/invoices/edit";

import Report from "@/components/workspace/report/index.vue";

import SettingUsers from "@/components/workspace/settings/users/index.vue";
import SettingUpcharges from "@/components/workspace/settings/upcharges/index.vue";
import SettingClients from "@/components/workspace/settings/clients/index.vue";

import { useDialogState } from "@/composables/useDialogState";

Vue.use(Router);

const login = {
  path: "/login",
  name: "login",
  component: Login,
  meta: { title: "Login - Vehicles" },
};

const activate = {
  path: "/activate/:token",
  name: "auth.activate",
  component: AuthActivate,
  meta: { title: "Activate Account - Vehicles" },
  props: true,
};

const workspace = {
  path: "/w",
  component: Workspace,
  children: [
    {
      path: "",
      redirect: "dashboard",
    },
    {
      path: "dashboard",
      name: "w.dashboard",
      component: Dashboard,
      meta: {
        title: () => {
          return `Dashboard -- app version 2.0`;
        },
      },
    },
    {
      path: "batchs",
      name: "w.batchs",
      component: Batchs,
      meta: { title: () => `Batchs of vehicles` },
    },
    {
      path: "batchs/preview/:id",
      name: "w.batchs.preview",
      component: BatchPreview,
      props: true,
      meta: { title: () => `Stock of Vehicles in Batch Selected` },
    },
    {
      path: "batchs/:scope",
      name: "w.batchs.stocks",
      component: Stock,
      props: (route) => ({ ...route.params, scopeId: route.query.scopeId }),
      meta: {
        title: (route) => {
          let scope = route.params.scope;
          return `Inventory of vehicles -- ${scope}`;
        },
      },
    },
    {
      path: "stock/:scope",
      name: "w.stock.scope",
      component: Stock,
      props: (route) => ({ ...route.params, scopeId: route.query.scopeId }),
      meta: {
        title: (route) => {
          let scope = route.params.scope;
          return `Inventory of vehicles -- ${scope}`;
        },
      },
    },
    {
      path: "search/vin",
      name: "w.search.vin",
      component: SearchVin,
      props: (route) => ({ vin: route.query.vin }),
      meta: {
        title: () => null,
      },
    },
    {
      path: "worklog/:scope",
      name: "w.worklog.scope",
      component: Worklog,
      props: true,
      meta: {
        title: (route) => {
          let scope = route.params.scope;
          return `Services -- ${scope}`;
        },
      },
    },
    {
      path: "worklog/:scope/:id",
      name: "w.worklog.scope.id",
      component: Worklog,
      props: true,
      meta: {
        title: (route) => {
          let scope = route.params.scope;
          return `Services -- ${scope}`;
        },
      },
    },
    /*
    {
      path: "invoice/:scope",
      name: "w.invoice.scope",
      component: Invoices,
      props: true,
      meta: {
        title: route => {
          let scope = route.params.scope;
          return `Invoices -- ${scope}`;
        }
      }
    },
    {
      path: "invoice/edit/:id",
      name: "w.worklog.edit",
      component: InvoiceEdit,
      props: route => ({
        id: route.params.id,
        isNew: route.params.isNew || false
      }),
      meta: {
        title: () => {
          return `Invoices`;
        }
      }
    },
    */
    {
      path: "report/:scope",
      name: "w.report.scope",
      component: Report,
      props: true,
      meta: {
        title: (route) => {
          const scope = route.params.scope;
          const title = ` ${
            scope === "detail"
              ? "pending for detail"
              : scope === "recon"
              ? "pending recon"
              : scope
          }`;
          return `Report -- ${title}`;
        },
      },
    },
    {
      path: "settings/users",
      name: "w.setting.users",
      component: SettingUsers,
      meta: { title: () => `Users` },
    },
    {
      path: "settings/upcharges",
      name: "w.setting.upcharges",
      component: SettingUpcharges,
      meta: { title: () => `Charges` },
    },
    {
      path: "settings/clients",
      name: "w.setting.clients",
      component: SettingClients,
      meta: { title: () => `Clients` },
    },
  ],
};

const router = new Router({
  routes: [
    login,
    activate,
    workspace,
    {
      path: "*",
      redirect: { name: "w.dashboard" },
    },
  ],
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login", "/activate"];
  //const authRequired = !publicPages.includes(to.path);
  const authRequired = !publicPages.some((x) => to.path.startsWith(x));
  const loggedIn = localStorage.getItem("user");

  const { dialogOpen } = useDialogState();
  if (dialogOpen.value) {
    dialogOpen.value = false;
    next(false);
    return;
  }

  if (authRequired && !loggedIn) {
    return next("/login");
  }

  next();
});

export default router;
