
import { PropType, computed, defineComponent, ref } from "vue";
import { DataTableHeader } from "vuetify";
import { useWindowSize } from "@vueuse/core";
import useRefDebounce from "@/composables/useRefDebounce";
import useContext from "@/composables/useContext";

export default defineComponent({
  name: "data-explorer",
  components: {},
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array as PropType<DataTableHeader[]>,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showSelect: {
      type: Boolean,
      default: true,
    },
    itemClass: {
      type: Function,
      default: () => "",
    },
    footerProps: {
      type: Object,
      default: () => ({ "items-per-page-options": [25, 100] }),
    },
  },
  setup(props, { emit, listeners }) {
    const filterMenu = ref(false);
    const { isPhone } = useContext();
    const windowSize = useWindowSize();
    const search = useRefDebounce("", 600);
    const selected = computed({
      get: () => props.value as { [key: string]: string }[],
      set: (val) => emit("input", val),
    });
    const DataTableElem = ref<{
      $children: [{ filteredItems: [] }];
      $el: HTMLElement;
    } | null>(null);
    const filteredItems = computed(
      () => DataTableElem.value?.$children[0].filteredItems || []
    );
    const selectAll = () => (selected.value = [...filteredItems.value]);

    const resetSelected = () => {
      if (search.value === "selected") {
        search.value = "";
      }
      selected.value = [];
    };

    const hasResetFilterListener = computed(
      () => listeners && !!listeners["reset-filter"]
    );

    const filterOptionBase = (
      items: Record<string, string>[],
      columnKey: string,
      columnValue: string | undefined
    ) => {
      let options: { key: string; value: string }[] = [];
      const ids = [...new Set(items.map((x) => x[columnKey]))];
      if (columnValue) {
        options = ids.map((x) => {
          const name = (items.find((y) => y[columnKey] === x) || {})[
            columnValue || "none"
          ];
          return { key: x, value: name };
        });
      } else {
        options = ids.map((x) => {
          return { key: x, value: x };
        });
      }
      return options.sort((a, b) =>
        a.value < b.value ? -1 : a.value > b.value ? 1 : 0
      );
    };

    const filterOptions = (columnKey: string, columnValue = "") => {
      const items = (props.items || []) as Record<string, string>[];
      return filterOptionBase(items, columnKey, columnValue);
    };

    const dataTableHeight = computed(() => {
      const dataTableElem = DataTableElem.value?.$el as HTMLElement;
      const dataTableElemTop =
        (dataTableElem?.getBoundingClientRect().top || 0) + 165;
      return !isPhone.value && windowSize.height.value > dataTableElemTop * 2
        ? `calc(100vh - ${dataTableElemTop}px)`
        : "";
    });

    const summatory = (items: { [key: string]: number }[], keyname: string) => {
      return items.reduce((a, b) => a + (b[keyname] || 0), 0);
    };

    return {
      filterMenu,
      isPhone,
      search,
      selected,
      DataTableElem,
      hasResetFilterListener,
      filterOptions,
      filteredItems,
      selectAll,
      resetSelected,
      dataTableHeight,
      summatory,
    };
  },
});
