<template>
  <div class="pa-2">
    <div class="headline font-weight-light">
      {{ line1 }}
    </div>
    <div class="title font-weight-light">
      {{ line2 }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    model() {
      return this.value || {};
    },
    line1() {
      return `${this.model.make} ${this.model.model} ${
        this.model.year || ""
      } - ${this.model.color}`;
    },
    line2() {
      return `${this.model.vin} - Stock No. ${this.model.stockNumber}`;
    },
  },
};
</script>
