<template>
  <v-layout row wrap>
    <v-flex xs12 sm9 md6 lg6>
      <search-bar
        ref="SearchBarElement"
        :vin="vin"
        @onSearch="onSearchOk"
        @onCancel="onSearchCancel"
      ></search-bar>
    </v-flex>
    <v-flex xs12 />
    <v-flex xs12>
      Press here to create new vehicle in inventory
      <v-btn text @click="newStock()">New ...</v-btn>
    </v-flex>
    <v-flex xs12 sm9 md6 lg6 v-show="anyItem">
      <stock-list :items="items" @onClickRow="openEditItem" />
    </v-flex>
    <item-edit
      v-model="openItemEditFlag"
      :key="`item-edit-dialog-${itemEditRefreshKey}`"
      :id="selectedItemId"
      :vin="vinForNewInventory"
      @onSave="resetItemEdit"
      @onCancel="closeEditItem"
    />
  </v-layout>
</template>

<script>
import searchBar from "./searchBar";
import itemEdit from "../stock/edit";
import stockList from "./list";

export default {
  props: {
    vin: {
      type: String,
      default: "",
    },
  },
  components: {
    "search-bar": searchBar,
    "item-edit": itemEdit,
    "stock-list": stockList,
  },
  data() {
    return {
      items: [],
      selectedItemId: null,
      openItemEditFlag: false,
      vinForNewInventory: null,
      itemEditRefreshKey: 1,
    };
  },
  computed: {
    anyItem() {
      return (this.items || []).length > 0;
    },
  },
  methods: {
    async newStock() {
      this.vinForNewInventory = this.$refs.SearchBarElement.vinlocal;
      await this.$wait(1);
      this.openItemEditFlag = true;
      this.selectedItemId = `new-${this.$random(1, 9999)}`;
    },
    onSearchOk(items) {
      this.items = items;
    },
    onSearchCancel() {
      this.resetItemEdit();
    },
    resetItemEdit() {
      this.openItemEditFlag = false;
      this.selectedItemId = null;
      this.items = [];
    },
    openEditItem(itemId) {
      this.selectedItemId = itemId;
      this.openItemEditFlag = true;
    },
    closeEditItem() {
      this.openItemEditFlag = false;
    },
  },
};
</script>

<style scoped>
.deleted {
  text-decoration: line-through;
}
</style>
