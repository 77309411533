<template>
  <div>
    <navigation-drawer :drawer.sync="drawer" />
    <navigation-bar :drawer.sync="drawer" />
    <v-main>
      <v-container fluid grid-list-lg>
        <date-range-bar />
        <router-view :key="`${this.$route.fullPath}`" />
      </v-container>
    </v-main>
  </div>
</template>
<script>
import NavigationDrawer from "./navigation-drawer.vue";
import NavigationBar from "./navigation-bar.vue";
import DateRangeBar from "./daterange-bar.vue";

export default {
  components: {
    NavigationDrawer,
    NavigationBar,
    DateRangeBar,
  },
  data: () => ({
    drawer: false,
  }),
};
</script>
