<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card-text class="white">
      <v-container grid-list-md>
        <v-layout wrap>
          <v-flex xs12 sm8>
            <v-layout align-center fill-height>
              <div>
                <div v-if="!(isNew || editVin)" class="subheading">
                  VIN: {{ model.vin }}
                </div>
                <v-text-field
                  ref="VinTextField"
                  v-else
                  v-model="model.vin"
                  label="VIN*"
                  required
                  :rules="[
                    (v) => !!v || 'VIN is required',
                    (v) => /\w{17}/i.test(v) || 'VIN is not valid',
                  ]"
                >
                </v-text-field>
              </div>
              <div>
                <v-btn
                  v-show="!isNew"
                  text
                  icon
                  small
                  color="blue lighten-2"
                  @click="onEditVin"
                >
                  <v-icon small>mdi-square-edit-outline</v-icon>
                </v-btn>
              </div>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm4>
            <v-layout align-center fill-height>
              <div>
                <div v-if="!(isNew || editStockNumber)" class="subheading">
                  Stock No.: {{ model.stockNumber }}
                </div>
                <v-text-field
                  v-else
                  ref="StockNumberTextField"
                  v-model="model.stockNumber"
                  label="Stock Number"
                  required
                  :rules="[(v) => !!v || 'Required']"
                ></v-text-field>
              </div>
              <div>
                <v-btn
                  v-show="!isNew"
                  text
                  icon
                  small
                  color="blue lighten-2"
                  @click="onEditStockNumber"
                >
                  <v-icon small>mdi-square-edit-outline</v-icon>
                </v-btn>
              </div>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm4>
            <v-text-field
              v-model="model.make"
              label="Make*"
              required
              :rules="[(v) => !!v || 'Required']"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm4>
            <v-text-field
              v-model="model.model"
              label="Model*"
              required
              :rules="[(v) => !!v || 'Required']"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm4>
            <v-text-field
              v-model="model.year"
              label="Model Year*"
              required
              :rules="[
                (v) => !!v || 'Required',
                (v) => /\d{4}/.test(v) || 'Year is not valid (ex. 2018)',
              ]"
            >
            </v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md4>
            <v-text-field
              v-model="model.color"
              label="Color*"
              required
              :rules="[(v) => !!v || 'Required']"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md4>
            <v-text-field
              v-model="model.miles"
              label="Miles*"
              required
              :rules="[
                (v) => !!v || 'Required',
                (v) => /[0-9\.]+/.test(v) || 'Miles is not valid (number)',
              ]"
            >
            </v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md4>
            <v-select
              v-model="model.clientId"
              :items="clientsData"
              item-text="name"
              item-value="id"
              label="Client"
              :rules="[(v) => !!v || 'Required']"
              required
              clearable
              @click:clear="model.clientId = null"
            ></v-select>
          </v-flex>
          <v-flex xs12 sm6 md4>
            <date-picker
              v-model="model.serviceInDate"
              label="Service In"
              :rules="[(v) => !!v || 'Required']"
            />
          </v-flex>
          <v-flex xs12 sm6 md4>
            <date-picker v-model="model.detailInDate" label="Detail in" />
          </v-flex>
          <v-flex xs12>
            <v-text-field v-model="model.comments" label="Comments">
            </v-text-field>
          </v-flex>
          <v-flex xs12><small>*indicates required field</small></v-flex>
        </v-layout>
      </v-container>

      <div class="d-flex">
        <div class="ma-2 font-italic grey--text">
          {{
            readonly ? "This Item is archived. Modification not allowed." : ""
          }}
        </div>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel()">Cancel</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="save()"
          :loading="loading"
          :disabled="readonly || loading"
        >
          Save
          <template v-slot:loader>
            <span>Saving...</span>
          </template>
        </v-btn>
      </div>
    </v-card-text>
  </v-form>
</template>

<script>
import { clientService } from "@/services";
import datePicker from "@/components/workspace/layout/date-picker";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    isNew: { type: Boolean, default: false },
  },
  components: {
    "date-picker": datePicker,
  },
  data() {
    return {
      model: {},
      valid: true,
      clientsData: [],
      loading: false,
      editVin: false,
      editStockNumber: false,
    };
  },
  computed: {
    readonly() {
      return this.model.archived;
    },
  },
  watch: {
    value: {
      handler: function () {
        this.model = { ...this.value };
      },
      immediate: true,
    },
  },
  async created() {
    await this.init();
  },
  methods: {
    async init() {
      try {
        this.clientsData = await clientService.actives();
      } catch (error) {
        this.$error(error);
      }
    },
    isValid() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.resetValidation();
    },
    cancel() {
      this.$emit("onCancel");
      this.reset();
    },
    async onEditVin() {
      await this.$wait(1);
      let error =
        (this.$refs.VinTextField && this.$refs.VinTextField.hasError) || false;
      if (this.editVin && !error) {
        this.editVin = false;
      } else {
        this.editVin = true;
      }
    },
    async onEditStockNumber() {
      await this.$wait(1);
      let error =
        (this.$refs.StockNumberTextField &&
          this.$refs.StockNumberTextField.hasError) ||
        false;
      if (this.editStockNumber && !error) {
        this.editStockNumber = false;
      } else {
        this.editStockNumber = true;
      }
    },
    async save() {
      if (!this.isValid()) {
        return;
      }
      let preservedWorkLogsDTS = this.value.workLogsDTS;
      this.model.workLogsDTS = preservedWorkLogsDTS;
      this.$emit("input", this.model);
      this.$emit("onSave");
      this.reset();
    },
  },
};
</script>

<style></style>

/* v => /[A-HJ-NPR-Z0-9]{17}/i.test(v) || 'VIN is not valid']" */
