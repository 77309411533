<template>
  <v-row>
    <v-col cols="12">
      <div class="d-flex justify-end ray-bar">
        <v-btn color="accent" @click="openNew()">
          <v-icon left>mdi-account-plus</v-icon>
          New User
        </v-btn>
        <v-btn color="error" @click="remove()">
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </div>
    </v-col>
    <v-col cols="12">
      <users-list
        :items="items"
        :selected.sync="selected"
        :loading="loading"
        @onItemSelect="openEdit"
      />
    </v-col>
    <user-edit v-model="editFormFlag" :item="editItem" @onSave="onEditSave" />
  </v-row>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from "vue";
import { userService } from "@/services";
import { guid } from "@/helpers";
import UsersList from "./list";
import UserEdit from "./edit";

export default {
  components: {
    UsersList,
    UserEdit,
  },
  beforeRouteLeave(to, from, next) {
    let readyToLeave = this.routerGuard();
    next(readyToLeave);
  },
  setup() {
    const { proxy } = getCurrentInstance();

    let items = ref([]);
    let selected = ref([]);
    let editItem = ref({});
    let editFormFlag = ref(false);
    let loading = ref(false);

    const fetchItems = async () => {
      loading.value = true;
      try {
        items.value = await userService.all();
      } catch (error) {
        proxy.$error(error);
      } finally {
        loading.value = false;
      }
    };
    const routerGuard = () => {
      let anyDialogOpen = editFormFlag.value || proxy.$confirm.isShow();
      let readyToLeave = !anyDialogOpen;
      if (anyDialogOpen) {
        editFormFlag.value = false;
        proxy.$confirm.cancel();
      }
      return readyToLeave;
    };
    const remove = async () => {
      let ok = await proxy.$confirm.show("Confirm to delete selected items?");
      if (!ok) {
        return;
      }
      try {
        let ids = selected.value.map((x) => x.id);
        await userService.tag("delete", ids);
        await fetchItems();
      } catch (error) {
        proxy.$error(error);
      }
    };
    const openNew = () => {
      editItem.value = {
        id: guid(),
        active: true,
        isNew: true,
      };
      editFormFlag.value = true;
    };
    const openEdit = (item) => {
      editItem.value = item;
      editFormFlag.value = true;
    };
    const onEditSave = async (item, notify) => {
      try {
        await userService.save(item, notify);
        await fetchItems();
      } catch (error) {
        proxy.$error(error);
      }
    };
    onMounted(async () => await fetchItems());
    return {
      loading,
      items,
      selected,
      remove,
      openNew,
      openEdit,
      onEditSave,
      editItem,
      editFormFlag,
      routerGuard,
    };
  },
};
</script>

<style></style>
