<template>
  <div>
    <div class="d-flex justify-end ray-bar">
      <v-menu v-if="anyItemSelected && $role.isAdmin" class="d-inline-block">
        <template v-slot:activator="{ on }">
          <v-btn color="accent" v-on="on">
            Tag Billed/Payroll
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="tag('billing')">
            <v-list-item-title>Billing</v-list-item-title>
          </v-list-item>
          <v-list-item @click="tag('payroll')">
            <v-list-item-title>Payroll</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu v-if="anyItemSelected && $role.isAdmin" class="d-inline-block">
        <template v-slot:activator="{ on }">
          <v-btn color="accent" v-on="on">
            Export CSV
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="csv('billing')">
            <v-list-item-title>Billing</v-list-item-title>
          </v-list-item>
          <v-list-item @click="csv('payroll')">
            <v-list-item-title>Payroll</v-list-item-title>
          </v-list-item>
          <v-list-item @click="zip('billing')">
            <v-list-item-title>Billing by Client</v-list-item-title>
          </v-list-item>
          <v-list-item @click="csv('accounting')">
            <v-list-item-title>Accounting</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        v-if="anyItemSelected && $role.isAdmin"
        color="accent"
        @click="onButtonTag('archive')"
      >
        <v-icon left>mdi-package-down</v-icon>
        Archive
      </v-btn>
      <batch-edit
        v-if="anyItemSelected"
        v-model="openBatchEdit"
        :items="selectedItemIds"
        @onRefresh="refresh"
      />
      <v-btn
        v-if="anyItemSelected && $role.isAdmin"
        color="error"
        @click="onButtonTag('delete')"
      >
        <v-icon>mdi-delete-outline</v-icon>
      </v-btn>
      <v-btn color="accent" @click="refresh()" title="Refresh">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </div>
    <a style="display: none" ref="fileLink">dummy</a>
  </div>
</template>

<script>
import { worklogService } from "@/services";
import BatchEdit from "./batch-edit";

export default {
  props: {
    selectedItemIds: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    dialog: { type: Boolean, default: false },
  },
  components: {
    BatchEdit,
  },
  data() {
    return {
      openBatchEdit: false,
    };
  },
  computed: {
    loadingLocal: {
      get() {
        return this.loading;
      },
      set(val) {
        this.$emit("update:loading", val);
      },
    },
    anyDialogOpen() {
      return this.openBatchEdit || this.$confirm.isShow();
    },
    anyItemSelected() {
      return this.selectedItemIds.length > 0;
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.$confirm.cancel();
      }
    },
    anyDialogOpen(val) {
      this.$emit("update:dialog", val);
    },
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
    async onButtonTag(tagType) {
      if (!this.isValid()) {
        return;
      }
      let title = `Confirm to ${tagType} selected items?`;
      let ok = await this.$confirm.show(title);
      if (!ok) {
        return;
      }
      await this.tag(tagType);
    },
    download(data) {
      var a = this.$refs.fileLink;
      var url = window.URL.createObjectURL(data.blob);
      a.href = url;
      a.download = data.filename;
      a.click();
    },
    isValid() {
      let ids = this.selectedItemIds;
      if (ids && ids.length > 0) {
        return true;
      } else {
        this.$error("No items selected.");
        return false;
      }
    },
    async tag(tagType) {
      this.loadingLocal = true;
      let ids = this.selectedItemIds;
      try {
        await worklogService.tag(tagType, ids);
      } catch (error) {
        this.$error(error);
      } finally {
        this.loadingLocal = false;
      }
      this.refresh();
    },
    async csv(csvType) {
      this.loadingLocal = true;
      let ids = this.selectedItemIds;
      try {
        let data = await worklogService.csv(csvType, ids);
        this.download(data);
      } catch (error) {
        this.$error(error);
      }
      this.loadingLocal = false;
    },
    async zip(zipType) {
      this.loadingLocal = true;
      let ids = this.selectedItemIds;
      try {
        let data = await worklogService.zip(zipType, ids);
        this.download(data);
      } catch (error) {
        this.$error(error);
      }
      this.loadingLocal = false;
    },
  },
};
</script>
