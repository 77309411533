<template>
  <v-row>
    <v-col cols="12" v-if="$role.isManager">
      <menubar-archived
        v-if="scope == 'archives' && $role.isAdmin"
        @refresh="refresh"
        :dialog.sync="dialog"
        :loading.sync="loading"
        :selectedItemIds="selectedWorklogIds"
      />
      <menubar-manager
        v-else
        @refresh="refresh"
        :dialog.sync="dialog"
        :loading.sync="loading"
        :selectedItemIds="selectedWorklogIds"
      />
    </v-col>
    <v-col cols="12">
      <component
        :is="
          isEmployeeScope ? 'worklogs-employee-list' : 'worklogs-default-list'
        "
        ref="WorkLogListElement"
        :items="items"
        :loading="loading"
        @onSelectItems="onSelectItems"
        @onClickRow="openEditForm"
      />
    </v-col>
    <tag-legend v-if="!isEmployeeScope" />
    <item-edit
      v-model="dialogEditForm"
      :id="selectedItemId"
      :key="`item-edit-dialog-${itemEditRefreshKey}`"
      @onSave="onSaveWorklogEdit"
      @onCancel="dialogEditForm = false"
    />
    <a style="display: none" ref="fileLink">dummy</a>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { isEmpty } from "@/helpers";
import { worklogService } from "@/services";
import WorklogsDefaultList from "./list-default";
import WorklogsEmployeeList from "./list-employee";
import ItemEdit from "../stock/edit";
import TagLegend from "./tag-legend";
import MenubarArchived from "./menubar-archived";
import MenubarManager from "./menubar-manager";

export default {
  props: {
    scope: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
  },
  components: {
    WorklogsDefaultList,
    WorklogsEmployeeList,
    ItemEdit,
    TagLegend,
    MenubarManager,
    MenubarArchived,
  },
  data() {
    return {
      items: [],
      loading: false,
      selectedItemId: null,
      dialogEditForm: false,
      selectedWorklogIds: [],
      dialog: false,
      itemEditRefreshKey: 1,
    };
  },
  computed: {
    isEmployeeScope() {
      return this.scope === "employee";
    },
    ...mapGetters("context", { filterDateRange: "filterDateRangeForServer" }),
  },
  watch: {
    async scope() {
      await this.refresh();
    },
    async filterDateRange() {
      await this.refresh();
    },
  },
  async created() {
    await this.refresh();
  },
  beforeRouteUpdate(to, from, next) {
    this.routerGuard(next);
  },
  beforeRouteLeave(to, from, next) {
    this.routerGuard(next);
  },
  methods: {
    routerGuard(next) {
      if (this.dialogEditForm || this.dialog) {
        this.dialog = this.dialogEditForm = false;
        next(false);
      } else {
        next();
      }
    },
    onSelectItems(ids) {
      this.selectedWorklogIds = ids;
    },
    async refresh() {
      if (!isEmpty(this.scope)) {
        this.loading = true;
        try {
          await this.fetchItems();
        } catch (error) {
          this.$error(error);
        }
        this.loading = false;
        this.itemEditRefreshKey++;
      } else {
        this.items = [];
      }
    },
    async fetchItems() {
      let scopeId = this.id;
      this.items = await worklogService.all(
        this.scope,
        scopeId,
        this.filterDateRange
      );
    },
    openEditForm(stockId) {
      this.selectedItemId = stockId;
      this.dialogEditForm = true;
    },
    async onSaveWorklogEdit() {
      this.dialogEditForm = false;
      await this.refresh();
    },
  },
};
</script>

<style scoped></style>
