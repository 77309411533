
import { defineComponent, ref, watch, computed } from "vue";
import { WorkLogExplorerDTS } from "@/swagger/swag-api";
import { listHelper } from "../layout/list-helper";
import { dateTimeUS } from "@/filters/index";
import { useFilter } from "@/composables/useFilter";
import DataExplorer from "../layout/data-explorer.vue";
import PictureEdit from "@/components/workspace/stock/edit/picture-edit.vue";
import useContext from "@/composables/useContext";

export default defineComponent({
  name: "list-default",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DataExplorer,
    PictureEdit,
  },
  setup(props, { emit }) {
    const { isPhone } = useContext();
    const selected = ref<WorkLogExplorerDTS[]>([]);
    const search = ref("");
    const pictureWorkLog = ref();
    const pictureDialog = ref(false);

    const { state: filterClient, action: filterByClient } = useFilter();

    const headers = [
      { text: "VIN", value: "vin" },
      { text: "Picture", value: "fileAttachmentCount" },
      { text: "Service", value: "name" },
      { text: "Description", value: "description" },
      { text: "Note", value: "note" },
      { text: "Number", value: "stockNumber" },
      { text: "Qty", value: "quantity" },
      { text: "Tag", value: "tag" },
      { text: "Done By", value: "assignedToName" },
      { text: "Date", value: "upChargeDate" },
      { text: "Client", value: "clientCode", filter: filterByClient },
    ];

    const headerKeys = headers.map((x) => x.value);

    const helper = listHelper;

    const dataTableItems = computed(() => {
      return listHelper.mapServiceItems(props.items);
    });

    watch(selected, () => {
      const ids = selected.value.map((x) => x.id);
      emit("onSelectItems", ids);
    });

    const onClickRow = (item: WorkLogExplorerDTS) => {
      emit("onClickRow", item.stockId);
    };

    const onOpenPicture = (item: unknown) => {
      pictureWorkLog.value = item;
      pictureDialog.value = true;
    };

    return {
      isPhone,
      selected,
      search,
      pictureWorkLog,
      pictureDialog,
      headers,
      headerKeys,
      helper,
      dataTableItems,
      dateTimeUS,
      onClickRow,
      onOpenPicture,
      filterClient,
    };
  },
});
