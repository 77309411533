<template>
  <v-layout row wrap justify-space-between v-if="showTitle">
    <v-flex>
      <div class="display-1 font-weight-light">{{ title }}</div>
    </v-flex>
    <v-flex shrink v-show="isDateRangeActive">
      <date-range @onRangeSelected="onRangeSelected" />
    </v-flex>
  </v-layout>
</template>

<script>
import DateRange from "../date-range.vue";

export default {
  components: {
    DateRange,
  },
  computed: {
    title() {
      return this.$route.meta.title(this.$route);
    },
    showTitle() {
      return this.$route.meta.title(this.$route) !== null;
    },
    isDateRangeActive() {
      let { name, params } = this.$route;
      if (name === "w.stock.scope") {
        return !(
          params["scope"] === "pending" || params["scope"] === "processed"
        );
      }
      return true;
    },
  },
  methods: {
    onRangeSelected(range) {
      this.$store.commit("context/filterDateRange", range);
    },
  },
};
</script>

<style></style>
