
import { computed, defineComponent, ref } from "vue";
import { useDialogState } from "@/composables/useDialogState";
import { onMounted } from "vue";
import { pictureService } from "@/services/picture.service";
import { useToast } from "@/composables/useToast";
import { useConfirm } from "@/composables/useConfirm";
import { usePictures } from "@/composables/usePictures";
import PictureGallery from "@/components/workspace/layout/picture-gallery.vue";
import PictureBtnUpload from "@/components/workspace/layout/picture-btn-upload.vue";
import useContext from "@/composables/useContext";

export default defineComponent({
  name: "picture-edit",
  components: { PictureGallery, PictureBtnUpload },
  props: {
    label: {
      type: String,
      default: "",
    },
    stockId: {
      type: String,
      default: "",
    },
    workLogId: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const { toast } = useToast();
    const { savePictureFile, deletePictureFile } = usePictures();
    const { confirm } = useConfirm();

    const { isManager } = useContext();

    const { dialogOpen: dialog } = useDialogState();
    const pictureIds = ref<string[]>([]);

    const close = () => {
      emit("onCancel", pictureIds.value.length);
    };

    const { getFileIds } = pictureService();

    const init = async () => {
      pictureIds.value = await getFileIds(props.stockId, props.workLogId);
    };

    const handleDelete = async (id: string) => {
      try {
        const ok = await confirm(
          "Delete Picture",
          "Are you sure you want to delete this picture?"
        );

        if (!ok) return;
        await deletePictureFile([id]);
        toast("Picture deleted");
        init();
      } catch (error) {
        toast((error as Error).message);
      }
    };

    const saving = ref(false);
    const save = async (pictures: File[] | undefined) => {
      try {
        saving.value = true;
        if (!pictures) return;
        for (const picture of pictures) {
          const response = (await savePictureFile(
            props.stockId,
            props.workLogId,
            picture
          )) as {
            fileId: string;
          };
          pictureIds.value.push(response.fileId);
          toast("Picture saved");
          emit("onSave", pictureIds.value.length);
        }
      } catch (error) {
        toast((error as Error).message);
      } finally {
        saving.value = false;
      }
    };

    onMounted(init);

    return {
      dialog,
      close,
      pictureIds,
      save,
      saving,
      handleDelete,

      isManager,
    };
  },
});
