import router from "@/router/index.js";
import { authenticationService } from "@/services";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? {
      status: { loggedIn: true },
      user: user,
    }
  : {
      status: {},
      user: null,
    };

export const authentication = {
  namespaced: true,
  state: initialState,
  getters: {
    BranchCode(state) {
      if (state.status.loggedIn && state.user) {
        let code = state.user.branchCode;
        return code;
      } else {
        return "";
      }
    },
    BranchName(state) {
      if (state.status.loggedIn && state.user) {
        let name = state.user.branchName;
        return name;
      } else {
        return "";
      }
    },
    role(state) {
      if (state.status.loggedIn) {
        const roles = state.user.roles.split(",");
        const isAdmin = roles.some((x) => x.trim() === "admin");
        const isClient = roles.some((x) => x.trim() === "client");
        const isManager = roles.some((x) =>
          ["manager", "admin"].includes(x.trim())
        );
        const isTechnician = roles.some((x) =>
          ["technician"].includes(x.trim())
        );
        return { isAdmin, isManager, isTechnician, isClient };
      }
      return false;
    },
  },
  actions: {
    login({ dispatch, commit }, { username, password }) {
      commit("loginRequest", { username });

      authenticationService.login(username, password).then(
        (user) => {
          commit("loginSuccess", user);
          router.push("/");
        },
        (error) => {
          commit("loginFailure", error);
          dispatch("alert/error", error, { root: true });
        }
      );
    },
    logout({ commit }) {
      authenticationService.logout();
      commit("logout");
    },
  },
  mutations: {
    loginRequest(state, user) {
      state.status = { loggingIn: true };
      state.user = user;
    },
    loginSuccess(state, user) {
      state.status = { loggedIn: true };
      state.user = user;
    },
    loginFailure(state) {
      state.status = {};
      state.user = null;
    },
    logout(state) {
      state.status = {};
      state.user = null;
    },
  },
};
