
import { defineComponent, ref, computed, watch, PropType } from "vue";
import { listHelper } from "../layout/list-helper";
import { dateUS } from "@/filters/index";
import { useFilter } from "@/composables/useFilter";
import DataExplorer from "../layout/data-explorer.vue";
import useContext from "@/composables/useContext";

type StockDTS = {
  id: string;
  vin: string;
  dispo: string;
  make: string;
  model: string;
  year: string;
  stockNumber: string;
  serviceInDate: string;
  detailInDate: string;
  age: string;
  clientCode: string;
  workLogCodes: string;
  vehicleKeyInDate: string;
};

export default defineComponent({
  name: "StockList",
  components: {
    DataExplorer,
  },
  props: {
    items: {
      type: Array as PropType<Array<StockDTS>>,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { isPhone } = useContext();
    const { role } = useContext();
    const dataExplorerSortBy = ref(["detailInDate", "serviceInDate"]);
    const dataExplorerSortDesc = ref([true, false]);
    const selected = ref<ReturnType<typeof mapStockItems>>([]);
    const search = ref("");

    const { state: filterClient, action: filterByClient } = useFilter();

    const headers = [
      { text: "Key", value: "vehicleKeyInDate" },
      { text: "VIN", value: "vin" },
      { text: "Dispo", value: "dispo" },
      { text: "Vehicle", value: "vehicle" },
      { text: "Number", value: "stockNumber" },
      { text: "Service In", value: "serviceInDate" },
      { text: "Detail In", value: "detailInDate" },
      { text: "Days", value: "age" },
      { text: "Client", value: "clientCode", filter: filterByClient },
      { text: "Services", value: "workLogCodes" },
    ];

    const headerByRoles = headers.filter((x) => {
      if (role.value.isManager) {
        return true;
      }
      if (role.value.isClient || role.value.isTechnician) {
        return x.value !== "vehicleKeyInDate";
      }
      return false;
    });

    const headerKeys = headerByRoles.map((x) => x.value);
    const helper = listHelper;

    const mapStockItems = (items: StockDTS[]) => {
      return items.map((x) => ({
        id: x.id,
        vin: x.vin,
        dispo: x.dispo,
        vehicle: `${x.make} ${x.model} ${x.year}`,
        stockNumber: x.stockNumber,
        serviceInDate: x.serviceInDate,
        detailInDate: x.detailInDate,
        vehicleKeyInDate: x.vehicleKeyInDate,
        age: x.age,
        clientCode: x.clientCode,
        workLogCodes: x.workLogCodes,
      }));
    };

    const dataTableItems = computed(() => {
      return mapStockItems(props.items);
    });

    watch(selected, () => {
      const ids = selected.value.map((x) => x.id);
      emit("onSelectItems", ids);
    });

    const onClickRow = (item: ReturnType<typeof mapStockItems>[0]) => {
      emit("onClickRow", item.id);
    };

    const loadingItemId = ref<string | undefined>();
    const onClickKey = async (item: ReturnType<typeof mapStockItems>[0]) => {
      loadingItemId.value = item.id;
      await new Promise((resolve) =>
        emit("onClickVehicleKeyIn", resolve, item.id)
      );
      loadingItemId.value = undefined;
    };

    const splitString = (str: string) => {
      if (!str) return [];
      return str.split(",");
    };

    return {
      dateUS,
      loadingItemId,
      selected,
      search,
      headerByRoles,
      headerKeys,
      dataExplorerSortBy,
      dataExplorerSortDesc,
      helper,
      dataTableItems,
      onClickRow,
      onClickKey,
      splitString,
      role,
      isPhone,
      filterClient,
    };
  },
});
