import { dateZ } from "@/helpers";

const currency = function (value: string | number) {
  const val = value.toString() || "0";
  return "$" + parseFloat(val).toFixed(2);
};

/**
 *  Converts a string into Capitalize
 *
 * 'abc' => 'Abc'
 */
const capitalize = function (value: string) {
  if (!value) return "";
  value = value.toString().toLowerCase();
  return value.charAt(0).toUpperCase() + value.slice(1);
};

const dateTimeUS = function (str: string) {
  if (str) {
    const dt = dateZ.parseISO(str);
    return dateZ.format(dt, "yyyy-MM-dd HH:mm");
  } else {
    return "";
  }
};

const dateUS = function (str: string) {
  if (str) {
    const dt = dateZ.parseISO(str);
    return dateZ.format(dt, "yyyy-MM-dd");
  } else {
    return "";
  }
};

export { dateUS, dateTimeUS, currency, capitalize };
