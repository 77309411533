<template>
  <div class="d-inline-block">
    <form ref="dummyFormUpload">
      <v-btn color="accent" dark @click="$refs.inputUpload.click()">
        <v-icon left>mdi-upload</v-icon>
        Import Inventory Batch...
      </v-btn>
      <input
        v-show="false"
        ref="inputUpload"
        type="file"
        @change="onFileChange"
        accept=".xlsx"
      />
    </form>
  </div>
</template>

<script>
import { batchService } from "@/services/batch.service";

export default {
  data() {
    return {
      filename: "",
      files: null,
    };
  },
  methods: {
    onFileChange($event) {
      const files = $event.target.files || $event.dataTransfer.files;
      this.files = files;
      if (files) {
        if (files.length > 0) {
          this.filename = [...files].map((file) => file.name).join(", ");
        } else {
          this.filename = null;
        }
      } else {
        this.filename = $event.target.value.split("\\").pop();
      }
      this.save();
    },
    save() {
      this.submit().then((x) => x);
    },
    async submit() {
      try {
        var batch = await batchService.upload(this.files);
        this.$toast(`${batch.message}`);
        this.$emit("batch-added", batch);
      } catch (err) {
        this.$error(err);
      }
    },
    reset() {
      this.$refs.dummyFormUpload.reset();
    },
  },
};
</script>

<style></style>
