
import { computed, defineComponent, ref, PropType, watch } from "vue";
import { worklogService } from "@/services";
import { listHelper } from "../layout/list-helper";
import { dateTimeUS, currency } from "@/filters/index";
import { useToast } from "@/composables/useToast";
import { WorkLogExplorerDTS } from "@/swagger/swag-api";
import DataExplorer from "../layout/data-explorer.vue";
import CurrencyLabel from "../layout/currency-label.vue";
import PictureEdit from "@/components/workspace/stock/edit/picture-edit.vue";
import useContext from "@/composables/useContext";

export default defineComponent({
  name: "list-employee",
  props: {
    items: {
      type: Array as PropType<WorkLogExplorerDTS[]>,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DataExplorer,
    CurrencyLabel,
    PictureEdit,
  },

  setup(props, { emit }) {
    const { error } = useToast();

    const { isPhone } = useContext();

    const selected = ref<WorkLogExplorerDTS[]>([]);
    const amount = ref(0);
    const confirmingId = ref("-none-");

    const pictureWorkLog = ref({} as WorkLogExplorerDTS);
    const pictureDialog = ref(false);

    const headers = [
      { text: "Confirm", value: "confirmedByUserAssigned" },
      { text: "VIN", value: "vin" },
      { text: "Service", value: "name" },
      { text: "Picture", value: "fileAttachmentCount" },
      { text: "Description", value: "description" },
      { text: "Note", value: "note" },
      { text: "Number", value: "stockNumber" },
      { text: "Qty", value: "quantity" },
      { text: "Date", value: "upChargeDate" },
      { text: "Pay", value: "upChargeCost" },
    ];

    const helper = listHelper;

    const dataTableItems = computed(() =>
      listHelper.mapServiceItemsForEmployees(props.items)
    );

    watch(selected, (newValue) => {
      let ids = newValue.map((x) => x.id);
      emit("onSelectItems", ids);
    });

    const onOpenPicture = (item: WorkLogExplorerDTS) => {
      pictureWorkLog.value = item;
      pictureDialog.value = true;
    };

    const onConfirm = async (item: WorkLogExplorerDTS) => {
      confirmingId.value = item.id;
      let ids = [item.id];
      let tag = item.confirmedByUserAssigned ? "unconfirm" : "confirm";
      try {
        await worklogService.tag(tag, ids);
        item.confirmedByUserAssigned = !item.confirmedByUserAssigned;
      } catch (err) {
        error(err as string);
      } finally {
        confirmingId.value = "-none-";
      }
    };

    return {
      headers,
      amount,
      selected,
      onConfirm,
      confirmingId,
      helper,
      dataTableItems,
      dateTimeUS,
      currency,
      pictureWorkLog,
      pictureDialog,
      isPhone,
      onOpenPicture,
    };
  },
});
