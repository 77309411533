
import { defineComponent, ref, computed, watch, onMounted } from "vue";
import { isEmpty } from "@/helpers";
import { reportService } from "@/services";
import { useToast } from "@/composables/useToast";
import { useFilter } from "@/composables/useFilter";
import store from "@/store/index";
import DataExplorer from "@/components/workspace/layout/data-explorer.vue";
import useContext from "@/composables/useContext";

export default defineComponent({
  name: "ReportCompletions",
  components: {
    DataExplorer,
  },
  setup() {
    const { isPhone } = useContext();
    const { error } = useToast();
    const filterDateRange = computed(
      () => store.getters["context/filterDateRangeForServer"]
    );
    const fileLink = ref<HTMLAnchorElement>();
    const loading = ref(false);
    const selected = ref([]);
    const items = ref([]);

    const { state: filterClient, action: filterByClient } = useFilter();

    const headers = [
      { text: "Vin", value: "vin" },
      { text: "Year", value: "year" },
      { text: "Make", value: "make" },
      { text: "Model", value: "model" },
      { text: "Color", value: "color" },
      { text: "Stock", value: "stockNumber" },
      { text: "In Service (days)", value: "daysInService" },
      { text: "In Detail (days)", value: "daysInDetail" },
      { text: "Client", value: "clientCode", filter: filterByClient },
    ];
    const empty = computed(() => isEmpty(items.value) && !loading.value);
    const init = async () => {
      try {
        loading.value = true;
        items.value = await reportService.get(
          "completion",
          filterDateRange.value
        );
      } catch (err) {
        error(err as string);
      } finally {
        loading.value = false;
      }
    };
    const download = (data: { blob: Blob; filename: string }) => {
      const a = fileLink.value as HTMLAnchorElement;
      const url = window.URL.createObjectURL(data.blob);
      a.href = url;
      a.download = data.filename;
      a.click();
    };
    const csv = async () => {
      loading.value = true;
      try {
        const ids = selected.value.length
          ? selected.value.map((x: { id: string }) => x.id)
          : items.value.map((x: { id: string }) => x.id);
        const data = await reportService.csv2("completion", ids);
        download(data);
      } catch (err) {
        error(err as string);
      } finally {
        loading.value = false;
      }
    };
    const refresh = init;
    watch(filterDateRange, refresh);

    onMounted(init);

    return {
      loading,
      items,
      headers,
      empty,
      csv,
      fileLink,
      selected,
      isPhone,
      filterClient,
    };
  },
});
