<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card class="elevation-0">
      <v-container class="grey lighten-3">
        <v-row dense>
          <v-col cols="5">
            <v-select
              v-model="employeeId"
              :items="employeesData"
              item-text="name"
              item-value="id"
              label="Employee"
              :rules="[(v) => !!v || 'Required']"
              required
              clearable
            ></v-select>
          </v-col>
          <v-col cols="5">
            <v-select
              v-model="upChargeId"
              :items="upChargesData"
              item-text="name"
              item-value="id"
              label="Service"
              :rules="[(v) => !!v || 'Required']"
              required
              clearable
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="quantity"
              :rules="numberRules"
              label="Qty"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="8">
            <v-text-field
              v-model.number="note"
              label="Comments"
              clearable
              @click:clear="note = null"
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="text-right mt-1 pt-3">
            <v-btn icon @click="add" class="mx-2">
              <v-icon large color="blue darken-2">mdi-plus-circle</v-icon>
            </v-btn>
            <v-btn icon @click="clear()" class="mr-1">
              <v-icon color="red darken-2">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-form>
</template>

<script>
import { guid, dateZ, isEmpty } from "@/helpers";
import { upChargesService, userService } from "@/services";

export default {
  data() {
    return {
      valid: false,
      upChargesData: [],
      employeesData: [],
      upChargeId: null,
      employeeId: null,
      quantity: 1,
      note: null,
      numberRules: [
        (v) => !!v || "Required",
        (v) => /^\d*\.?\d{0,2}$/.test(v) || "Number (ex. 0.50)",
      ],
    };
  },
  async created() {
    await this.init();
  },
  methods: {
    async init() {
      try {
        const chargesPromise = upChargesService.actives();
        const employeePromise = userService.technicians();
        this.upChargesData = await chargesPromise;
        this.employeesData = await employeePromise;
      } catch (error) {
        this.$error(error);
      }
    },
    isValid() {
      return this.$refs.form.validate();
    },
    async add() {
      if (!this.isValid()) {
        return;
      }
      const upCharge = this.upChargesData.find((x) => x.id == this.upChargeId);
      const employee = this.employeesData.find((x) => x.id == this.employeeId);
      const note = this.note;
      const quantity = this.quantity;
      const now = dateZ.formatISO(new Date());
      let workLog = {
        id: guid(),
        upChargeId: upCharge.id,
        upChargeName: upCharge.name,
        upChargeDate: now,
        billed: false,
        deleted: false,
        assignedToUserId: employee.id,
        assignedToName: employee.name,
        note: note,
        quantity: quantity,
      };
      this.$emit("onAdd", workLog);
      const employeeIdBak = this.employeeId;
      await this.clear();
      this.employeeId = employeeIdBak;
    },
    async clear() {
      this.$refs.form.reset();
      await this.$nextTick();
      this.quantity = 1;
      this.upChargeId = null;
      this.note = "";
    },
    isClear() {
      return isEmpty(this.upChargeId) && isEmpty(this.note);
    },
  },
};
</script>

<style></style>
