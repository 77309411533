<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    persistent
    :fullscreen="$isPhone"
    :hide-overlay="$isPhone"
    :scrollable="$isPhone"
    :transition="$isPhone ? 'dialog-bottom-transition' : null"
  >
    <v-card>
      <v-card-title
        class="blue darken-3 py-2 title white--text"
        style="max-height: 60px"
      >
        <v-btn icon dark @click="cancel()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <span> Client Edit </span>
        <v-spacer />
      </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text class="white">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm8>
                <v-text-field
                  v-model="model.name"
                  label="Client Name*"
                  required
                  :rules="[(v) => !!v || 'Name is required']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm4>
                <v-text-field
                  v-model="model.code"
                  label="Client Code*"
                  required
                  :rules="[(v) => !!v || 'Code is required']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-checkbox
                  v-model="model.active"
                  :label="`Active`"
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions class="white">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel()">Close</v-btn>
          <v-btn color="blue darken-1" text @click="save()">Save</v-btn>
        </v-card-actions>
        <div></div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    model: {
      name: "",
      code: "",
      active: true,
    },
    valid: false,
    numberRules: [
      (v) => !!v || "Value required",
      (v) => /\d+\.?\d*/.test(v) || "Must be a number",
    ],
  }),
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  watch: {
    "item.id": function () {
      this.model = this.item;
    },
  },
  methods: {
    isValid() {
      return this.$refs.form.validate();
    },
    save() {
      if (this.isValid()) {
        this.dialog = false;
        this.$emit("onSave", this.model);
      }
    },
    cancel() {
      this.dialog = false;
      this.$emit("onCancel");
    },
  },
};
</script>
