<template>
  <DataExplorer
    ref="ListElement"
    v-model="selected"
    :headers="headers"
    :items="items"
    :loading="loading"
    :search.sync="search"
  >
    <template v-slot:item="props">
      <tr :key="`item-${props.item.id}`">
        <td>
          <v-checkbox
            :input-value="props.isSelected"
            @click="props.select(!props.isSelected)"
          ></v-checkbox>
        </td>
        <td>
          <v-btn
            class="btn-table"
            text
            small
            color="primary"
            @click="onItemSelect(props.item)"
            >{{ props.item.name }}</v-btn
          >
        </td>
        <td>{{ props.item.code }}</td>
        <td>{{ props.item.printOrder }}</td>
        <td>{{ props.item.price }}</td>
        <td>{{ props.item.cost }}</td>
        <td>
          <active-dot v-model="props.item.active" />
        </td>
      </tr>
    </template>
  </DataExplorer>
</template>

<script>
import activeDot from "@/components/workspace/layout/active";
import DataExplorer from "../../layout/data-explorer.vue";

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    "active-dot": activeDot,
    DataExplorer,
  },
  data() {
    return {
      selected: [],
      search: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Code", value: "code" },
        { text: "Print Order", value: "printOrder" },
        { text: "Price", value: "price" },
        { text: "Cost", value: "Cost" },
        { text: "Status", value: "active" },
      ],
    };
  },
  methods: {
    onItemSelect(item) {
      this.$emit("onItemSelect", item);
    },
  },
};
</script>

<style scoped>
.btn-table {
  padding: 0;
  margin: 0;
}
</style>
