<template>
  <DataExplorer
    v-model="selected"
    :headers="headers"
    :items="items"
    :loading="loading"
    :search.sync="search"
  >
    <template v-slot:item="props">
      <tr :key="`item-${props.item.id}`">
        <td>
          <v-checkbox
            :input-value="props.isSelected"
            @click="props.select(!props.isSelected)"
          ></v-checkbox>
        </td>
        <td>
          <v-btn
            class="btn-table"
            text
            small
            color="primary"
            @click="onItemStockSelect(props.item)"
            >{{ props.item.name }}</v-btn
          >
        </td>
        <td>
          <v-chip label small color="info">{{ props.item.stocksCount }}</v-chip>
        </td>
        <td>
          <v-chip
            label
            small
            :color="
              props.item.stocksCount == props.item.stocksPendingCount
                ? 'success'
                : 'error'
            "
            >{{ `${props.item.stocksPendingCount}` }}</v-chip
          >
        </td>
        <td>{{ dateTimeUS(props.item.createdAt) }}</td>
      </tr>
    </template>
  </DataExplorer>
</template>

<script>
import { dateTimeUS } from "@/filters/index";
import DataExplorer from "../layout/data-explorer.vue";

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DataExplorer,
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Batchs", align: "left", value: "name" },
        { text: "Inventory", value: "stocksCount" },
        { text: "Inventory (pending)", value: "stocksPending" },
        { text: "Imported", value: "createdAt" },
      ],
    };
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    dateTimeUS,
    stock(item) {
      return `${item.stocksCount} (${item.stocksPendingCount})`;
    },
    onItemWorkLogSelect(item) {
      this.$router.push({
        name: "w.worklog.scope.id",
        params: { scope: "batch", id: item.id },
      });
    },
    onItemStockSelect(item) {
      this.$router.push({
        name: "w.batchs.stocks",
        params: { scope: "batch" },
        query: { scopeId: item.id },
      });
    },
  },
};
</script>

<style scoped></style>
