<template>
  <v-row>
    <v-col cols="12">
      <div class="d-flex justify-end ray-bar">
        <batch-upload @batch-added="onBatchAdded" />
        <v-btn
          :disabled="loading"
          color="error"
          @click="deleteIt()"
          title="Delete selected items"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </v-col>
    <v-col cols="12">
      <batch-list v-model="selected" :items="items" :loading="loading" />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { batchService } from "@/services";
import BatchList from "./list.vue";
import BatchUpload from "./upload.vue";

export default {
  components: {
    BatchList,
    BatchUpload,
  },
  data() {
    return {
      /** @type {BatchItem[]} */
      items: [],
      i: 0,
      loading: false,
      selected: [],
    };
  },
  computed: {
    ...mapGetters("context", {
      filterDateRange: "filterDateRangeForServer",
    }),
  },
  watch: {
    async filterDateRange() {
      await this.refresh();
    },
  },
  async created() {
    await this.refresh();
  },
  methods: {
    async refresh() {
      await this.fetchItems();
    },
    async fetchItems() {
      try {
        this.loading = true;
        this.selected = [];
        this.items = await batchService.all(this.filterDateRange);
      } catch (error) {
        this.$error(error);
      } finally {
        this.loading = false;
      }
    },
    async onBatchAdded() {
      await this.fetchItems();
    },
    async deleteIt() {
      if (!this.isValid()) {
        return;
      }
      let ok = await this.$confirm.show("Confirm to delete selected items?");
      if (!ok) {
        return;
      }
      await this.tag("delete");
    },
    async tag(tagType) {
      this.loading = true;
      try {
        let ids = this.selected.map((x) => x.id);
        await batchService.tag(tagType, ids);
        await this.fetchItems();
      } catch (error) {
        this.$error(error);
      }
      this.loading = false;
    },
    isValid() {
      if (this.selected.length == 0) {
        this.$error("No items selected.");
        return false;
      }
      return true;
    },
  },
};
</script>
