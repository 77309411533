import { config } from "@/config";
import { authenticationService } from "./authentication.service";
let { authHeader, handleResponse, handleFileDownloadResponse } =
  authenticationService;

export const reportService = {
  get,
  csv,
  csv2,
  fileDownload,
};

async function get(report, range) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(
    `${config.api.base}/reports/${report}/${range}`,
    requestOptions
  );
  return await handleResponse(response);
}

async function csv2(report, ids) {
  let contentType = { "Content-Type": "application/json" };
  const requestOptions = {
    method: "POST",
    headers: { ...contentType, ...authHeader() },
    body: JSON.stringify(ids),
  };
  let response = await fetch(
    `${config.api.base}/reports/${report}/csv2`,
    requestOptions
  );
  return await handleFileDownloadResponse(response);
}

async function csv(report, range) {
  let contentType = { "Content-Type": "application/json" };
  const requestOptions = {
    method: "POST",
    headers: { ...contentType, ...authHeader() },
    body: JSON.stringify({}),
  };
  let response = await fetch(
    `${config.api.base}/reports/${report}/csv/${range}`,
    requestOptions
  );
  return await handleFileDownloadResponse(response);
}

async function fileDownload(endpoint, payload) {
  let contentType = { "Content-Type": "application/json" };
  const requestOptions = {
    method: "POST",
    headers: { ...contentType, ...authHeader() },
    body: JSON.stringify(payload),
  };
  let response = await fetch(
    `${config.api.base}/reports/${endpoint}`,
    requestOptions
  );
  return await handleFileDownloadResponse(response);
}
