import { ref, computed } from "vue";

const state = {
  open: ref(false),
  title: ref(""),
  message: ref(""),
  resolve: (() => {
    return;
  }) as (n: boolean) => void,
};

const getters = {
  open: computed(() => state.open.value),
  title: computed(() => state.title.value),
  message: computed(() => state.message.value),
};

function actions() {
  const confirm = (title: string | undefined, message: string | undefined) => {
    state.title.value = title || "Please Confirm!!";
    state.message.value = message || "Are you sure?";
    return new Promise<boolean>((res) => {
      state.resolve = res;
      state.open.value = true;
    });
  };

  const ok = () => {
    state.resolve(true);
    state.open.value = false;
  };

  const cancel = () => {
    state.resolve(false);
    state.open.value = false;
  };

  return { confirm, ok, cancel };
}

export function useConfirm() {
  return { ...getters, ...actions() };
}
