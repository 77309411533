export default {
  install: function (Vue, options) {
    if (this.installed || false) {
      return;
    }
    this.installed = true;
    document.addEventListener("visibilitychange", function () {
      let store = options.store;
      store.commit("context/visibility", !document.hidden);
    });
  },
};
