export default {
  install(Vue) {
    if (this.installed || false) {
      return;
    }
    this.installed = true;
    let isPhone = window.innerWidth < 481 || window.innerHeight < 481;
    Vue.prototype.$isPhone = isPhone;
  },
};
