
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "picture-btn-upload",
  components: {},
  props: {
    uploading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["upload"],
  setup(_, { emit }) {
    const refInput = ref<HTMLInputElement | null>(null);
    const files = ref<File[]>([]);
    const filenames = ref<string>("");

    const handleFileChange = ($event: Event) => {
      const inputElement = $event.target as HTMLInputElement;
      const filesInput = inputElement.files || [];
      files.value = Array.from(filesInput);
      filenames.value = files.value.map((file) => file.name).join(", ");
      handleSubmit();
    };

    const handleSubmit = () => {
      emit("upload", files.value);
    };

    return { handleFileChange, filenames, refInput };
  },
});
