<template>
  <v-col cols="12" md="4">
    <v-card class="mx-auto fill-height" :color="format.color" max-width="600">
      <v-card-title>
        <v-icon color="grey darken-1" class="mr-5" size="80" @click="action()">
          {{ format.icon }}
        </v-icon>
      </v-card-title>
      <v-card-title>
        <v-layout column align-start>
          <div class="display-1 grey--text text-uppercase">
            {{ format.title }}
          </div>
          <div>
            <span
              class="display-2 font-weight-bold mr-2"
              v-text="value || '—'"
            ></span>
            <strong>{{ format.text }}</strong>
          </div>
        </v-layout>
        <v-spacer></v-spacer>
        <v-btn icon class="align-self-start" size="28" @click="action()">
          <v-icon>mdi-arrow-right-thick</v-icon>
        </v-btn>
      </v-card-title>
    </v-card>
  </v-col>
</template>
<script>
let format = {
  icon: "mdi-cancel",
  title: "empty",
  text: "empty",
  color: "grey lighten-4",
};

export default {
  props: {
    format: {
      type: Object,
      default: () => format,
    },
    value: null,
  },
  data: () => ({}),
  methods: {
    action() {
      this.$emit("onClick");
    },
  },
};
</script>
