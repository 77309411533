import { config } from "@/config";
import { authenticationService } from "./authentication.service";

let { authHeader, handleResponse } = authenticationService;

export const userService = {
  all,
  technicians,
  save,
  tag,
};

async function all() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(`${config.api.base}/users`, requestOptions);
  return await handleResponse(response);
}

async function technicians() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(
    `${config.api.base}/users/role/technicians`,
    requestOptions
  );
  return await handleResponse(response);
}

async function save(entity, notify) {
  const contentType = { "Content-Type": "application/json" };
  const requestOptions = {
    method: "POST",
    headers: { ...contentType, ...authHeader() },
    body: JSON.stringify(entity),
  };

  let response = await fetch(
    `${config.api.base}/users/${notify ? "email" : ""}`,
    requestOptions
  );
  return await handleResponse(response);
}

async function tag(tagType, ids) {
  const contentType = { "Content-Type": "application/json" };
  const requestOptions = {
    method: "POST",
    headers: { ...contentType, ...authHeader() },
    body: JSON.stringify(ids),
  };

  let response = await fetch(
    `${config.api.base}/users/tag/${tagType}`,
    requestOptions
  );
  return await handleResponse(response);
}
