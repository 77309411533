<template>
  <v-layout row wrap>
    <v-flex sm12>
      <h3>Batch of Vehicles Detail</h3>
      <br />
    </v-flex>
    <v-flex sm12>
      <v-toolbar text color="white" class="white elevation-1">
        <v-toolbar-title>{{ model.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small @click="back()">Back</v-btn>
      </v-toolbar>
    </v-flex>
    <v-flex lg12>
      <stock-list :items="model.stocks" />
    </v-flex>
  </v-layout>
</template>

<script>
import stockList from "../stock/list";
import { batchService } from "@/services";

export default {
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      model: {},
    };
  },
  async created() {
    await this.fetchItems(this.id);
  },
  components: {
    "stock-list": stockList,
  },
  methods: {
    fetchItems(batchId) {
      batchService.get(batchId).then((x) => (this.model = x));
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
