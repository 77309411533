
import { defineComponent, PropType, ref } from "vue";
import { useNetwork } from "@vueuse/core";
import { useDialogState } from "@/composables/useDialogState";
import { config } from "@/config";
import useContext from "@/composables/useContext";
import PictureOff from "./picture-off.vue";
import ZoomImage from "./zoom-image.vue";

export default defineComponent({
  name: "PictureGallery",
  components: {
    PictureOff,
    ZoomImage,
  },
  props: {
    ids: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  emits: ["remove"],
  setup(_, { emit }) {
    const { isOnline } = useNetwork();
    const { isPhone, isManager } = useContext();
    const selected = ref("");

    const thumbnail = (id: string) =>
      `${config.api.base}/fileAttachment/thumbnail/${id}`;

    const picture = (id: string) => `${config.api.base}/fileAttachment/${id}`;

    const onPicture = (id: string) => {
      dialog.value = true;
      selected.value = id;
    };

    const handleDelete = async (id: string) => {
      emit("remove", id);
      dialog.value = false;
    };

    const { dialogOpen: dialog } = useDialogState();

    return {
      dialog,
      isPhone,
      thumbnail,
      selected,
      picture,
      onPicture,
      isOnline,
      handleDelete,
      isManager,
    };
  },
});
