import { config } from "@/config";
import { authenticationService } from "./authentication.service";

let { authHeader, handleResponse } = authenticationService;

export const upChargesService = {
  all,
  actives,
  save,
  tag,
};

async function all() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(`${config.api.base}/upCharges`, requestOptions);
  return await handleResponse(response);
}

async function actives() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(
    `${config.api.base}/upCharges/tag/actives`,
    requestOptions
  );
  return await handleResponse(response);
}

async function save(entity) {
  let contentType = { "Content-Type": "application/json" };
  const requestOptions = {
    method: "POST",
    headers: { ...contentType, ...authHeader() },
    body: JSON.stringify(entity),
  };
  let response = await fetch(`${config.api.base}/upCharges`, requestOptions);
  return await handleResponse(response);
}

async function tag(tagType, ids) {
  let contentType = { "Content-Type": "application/json" };
  const requestOptions = {
    method: "POST",
    headers: { ...contentType, ...authHeader() },
    body: JSON.stringify(ids),
  };
  let response = await fetch(
    `${config.api.base}/upCharges/tag/${tagType}`,
    requestOptions
  );
  return await handleResponse(response);
}
