import { computed } from "vue";
import { useWindowSize } from "@vueuse/core";
import store from "@/store/index";

const useContext = () => {
  const { width } = useWindowSize();

  const role = computed(() => store.getters["authentication/role"]);

  const isAdmin = computed(() => role.value.isAdmin);
  const isManager = computed(() => role.value.isManager);

  const filterDateRange = computed(
    () => store.getters["context/filterDateRangeForServer"]
  );

  const isPhone = computed(() => {
    return width.value <= 480;
  });

  return { isPhone, role, filterDateRange, isAdmin, isManager };
};

export default useContext;
