import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router/index.js";
import store from "./store/index.js";
import vuetify from "./plugins/vuetify";
import { mapGetters } from "vuex";
import "./plugins/vuetify";
import visibility from "./plugins/visibility";
import mobile from "./plugins/mobile";
import toast from "./plugins/toast";
import confirm from "./plugins/confirm";
import "@/assets/styles.css";
//import "@/assets/roboto-fontface-skinny.css"; //import "roboto-fontface/css/roboto/roboto-fontface.css";
//import "@/assets/materialdesignicons-skinny.css"; //import "@mdi/font/css/materialdesignicons.css";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.config.productionTip = false;
Vue.use(toast, { store });
Vue.use(confirm, { context: { vuetify } });
//Vue.use(VueQuagga);
Vue.use(visibility, { store });
Vue.use(mobile);
Vue.prototype.$wait = (ms: number) => new Promise((r) => setTimeout(r, ms));
Vue.mixin({
  computed: {
    ...mapGetters({
      $role: "authentication/role", //isAdmin,isManager,isTechnician
    }),
  },
  methods: {
    $random(min, max) {
      return Math.floor(Math.random() * (+max - +min)) + +min;
    },
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
