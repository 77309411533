<template>
  <v-menu
    v-model="dialog"
    :nudge-width="40"
    :close-on-content-click="false"
    class="d-inline-block"
  >
    <template v-slot:activator="{ on }">
      <v-btn outlined color="primary" dark v-on="on">
        <v-icon left>mdi-calendar</v-icon>
        {{ title(current) }}
        <v-icon right>mdi-menu-down</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="d-block">
        <div>Filter by date range</div>
      </v-card-title>

      <v-card-text>
        <div class="v-date-range__pickers">
          <v-date-picker
            v-model="selected"
            :events="hightlight"
            :event-color="highlightClasses"
          />
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <div>
          <v-icon color="primary" class="mr-2">mdi-calendar</v-icon>
          <span class="primary--text subheading">{{ title(range) }}</span>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel()">Cancel</v-btn>
        <v-btn color="primary" text @click="save()">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
//---- menu ---------------
//:nudge-width="200"
//offset-x
//-------------------------
import { dateZ } from "@/helpers";

const ranges = {
  Today: { name: "Today", start: dateZ.today(), end: dateZ.today() },
  Yesterday: { name: "Yesterday", start: dateZ.today(), end: dateZ.today() },
  ThisWeek: {
    name: "This Week",
    start: dateZ.sundayOfWeek(),
    end: dateZ.sundayOfWeek(1),
  },
  PreviousWeek: {
    name: "Previous Week",
    start: dateZ.sundayOfWeek(-1),
    end: dateZ.sundayOfWeek(0),
  },
  ThisMonth: {
    name: "This Month",
    start: dateZ.firstDayOfMonth(),
    end: dateZ.lastDayOfMonth(),
  },
  PreviousMonth: {
    name: "Previous Month",
    start: dateZ.firstDayOfMonth(-1),
    end: dateZ.lastDayOfMonth(-1),
  },
  LastThreeMonths: {
    name: "Last Three Months",
    start: dateZ.firstDayOfMonth(-3),
    end: dateZ.lastDayOfMonth(),
  },
};

export default {
  data: () => ({
    range: {
      start: "",
      end: "",
    },
    current: {
      start: "",
      end: "",
    },
    starting: true,
    hightlight: [],
    selected: null,
    highlightClasses: {},
    dialog: false,
  }),
  created() {
    this.ranges = ranges;
    let today = dateZ.today();
    this.init(today);
  },
  computed: {},
  watch: {
    selected(val) {
      let vdate = dateZ.parseISO(val + "T00:00");
      this.refresh(vdate);
    },
  },
  methods: {
    title(data) {
      let s = dateZ.format(data.start, "yyyy-MM-dd");
      let e = dateZ.format(data.end, "yyyy-MM-dd");
      return `${s} to ${e}`;
    },
    init(vdate) {
      this.range = {
        start: new Date(vdate.valueOf()),
        end: new Date(vdate.valueOf()),
      };
      this.current = {
        start: new Date(vdate.valueOf()),
        end: new Date(vdate.valueOf()),
      };
      this.$emit("onRangeSelected", this.current);
    },
    refresh(vdate) {
      if (this.starting) {
        this.range.start = this.range.end = vdate;
      } else {
        if (vdate < this.range.start) {
          this.range.start = this.range.end = vdate;
          this.starting = true;
        } else {
          this.range.end = vdate;
        }
      }
      this.starting = !this.starting;
      this.hightlightRange();
    },
    hightlightRange() {
      let start = this.range.start;
      let end = this.range.end;
      let dates = [];
      let classes = {};
      let i = 0;
      for (
        let dt = new Date(start.valueOf());
        dt <= end;
        dt.setDate(dt.getDate() + 1)
      ) {
        let day = dateZ.format(dt, "yyyy-MM-dd");
        dates.push(day);
        classes[day] = `v-date-range__in-range blue lighten-5`;
        if (i++ > 365 * 2) break;
      }
      this.hightlight = dates;
      this.highlightClasses = classes;
    },
    save() {
      this.dialog = false;
      this.starting = true;
      this.current = {
        start: new Date(this.range.start.valueOf()),
        end: new Date(this.range.end.valueOf()),
      };
      this.$emit("onRangeSelected", this.current);
    },
    cancel() {
      this.dialog = false;
      this.starting = true;
    },
  },
};
</script>

<style scoped>
.v-date-range__pickers >>> .v-date-picker-table__events {
  height: 100%;
  width: 100%;
  top: 0;
  z-index: -1;
}

.v-date-range__pickers >>> .v-date-picker-table table {
  width: auto;
  margin: auto;
  border-collapse: collapse;
}

.v-date-range__pickers >>> .v-date-picker-table table th,
.v-date-range__pickers >>> .v-date-picker-table table td {
  height: 32px;
  width: 32px;
}

.v-date-range__pickers
  >>> .v-date-picker-table
  table
  td
  .v-btn.v-btn--outlined {
  border: none;
  box-shadow: 0 0 0 1px currentColor inset;
}

.v-date-range__pickers
  >>> .v-date-picker-table
  table
  td
  .v-btn.v-btn--active::before {
  background-color: transparent !important;
}

.v-date-range__pickers >>> .v-date-range__in-range {
  height: 100%;
  width: 100%;
  margin: 0;
  border-radius: 0;
}
</style>
