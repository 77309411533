<template>
  <v-main>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-toolbar color="blue darken-3" dark>
            <v-toolbar-title>Activate your account</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card>
            <v-card-title primary-title>
              <div>
                <div class="headline">Enter new password</div>
                <div>
                  Enter a strong password and click the Submit button. After you
                  submit your password use your email address and this password
                  to login.
                </div>
              </div>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                autocomplete="off"
                role="presentation"
              >
                <v-text-field
                  v-model="password0"
                  prepend-icon="mdi-lock"
                  label="New Password"
                  type="password"
                  required
                  :rules="passwordRules0()"
                  autocomplete="new-password"
                ></v-text-field>
                <v-text-field
                  v-model="password1"
                  prepend-icon="mdi-lock"
                  label="Re-type Again"
                  type="password"
                  required
                  :rules="passwordRules1()"
                  autocomplete="new-password"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="cancel()" large>Cancel</v-btn>
              <v-btn color="blue dark-1" text dark @click="save()" large
                >Submit</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { authenticationService } from "@/services";

export default {
  props: {
    token: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    valid: false,
    username: "",
    password0: "",
    password1: "",
    submitted: false,
  }),
  computed: {
    loggingIn() {
      return this.$store.state.authentication.status.loggingIn;
    },
  },
  methods: {
    async save() {
      if (!this.isValid()) return;
      try {
        await authenticationService.activate(this.token, this.password0);
        this.cancel();
      } catch (error) {
        this.$error(error);
      }
    },
    passwordRules0() {
      return [
        (v) => !!v || "Required",
        (v) =>
          /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/.test(v) ||
          "At least one numeric and eight characters or longer",
      ];
    },
    passwordRules1() {
      return [
        (v) => !!v || "Required",
        (v) => v === this.password0 || "Passwords not match",
      ];
    },
    isValid() {
      return this.$refs.form.validate();
    },
    cancel() {
      this.$router.push({ name: "w.dashboard" });
    },
  },
};
</script>
