import { config } from "@/config";
import { authenticationService } from "./authentication.service";
let { authHeader, handleResponse, handleFileDownloadResponse } =
  authenticationService;

export const worklogService = {
  all,
  get,
  save,
  zip,
  csv,
  tag,
  batchSave,
};

async function all(scope, scopeId, range) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const parmScopeId = scopeId ? `scopeId=${scopeId}` : "";
  let response = await fetch(
    `${config.api.base}/worklogs/scope/${scope}/${range}?${parmScopeId}`,
    requestOptions
  );
  return await handleResponse(response);
}

async function get(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(
    `${config.api.base}/worklogs/${id}`,
    requestOptions
  );
  return await handleResponse(response);
}

async function save(entity) {
  let contentType = { "Content-Type": "application/json" };
  const requestOptions = {
    method: "POST",
    headers: { ...contentType, ...authHeader() },
    body: JSON.stringify(entity),
  };
  let response = await fetch(`${config.api.base}/worklogs`, requestOptions);
  return await handleResponse(response);
}

async function zip(fileType, ids) {
  let contentType = { "Content-Type": "application/json" };
  const requestOptions = {
    method: "POST",
    headers: { ...contentType, ...authHeader() },
    body: JSON.stringify(ids),
  };
  let response = await fetch(
    `${config.api.base}/worklogs/zip/${fileType}`,
    requestOptions
  );
  return await handleFileDownloadResponse(response);
}

async function csv(fileType, ids) {
  let contentType = { "Content-Type": "application/json" };
  const requestOptions = {
    method: "POST",
    headers: { ...contentType, ...authHeader() },
    body: JSON.stringify(ids),
  };
  let response = await fetch(
    `${config.api.base}/worklogs/csv/${fileType}`,
    requestOptions
  );
  return await handleFileDownloadResponse(response);
}

async function tag(tagType, ids) {
  let contentType = { "Content-Type": "application/json" };
  const requestOptions = {
    method: "POST",
    headers: { ...contentType, ...authHeader() },
    body: JSON.stringify(ids),
  };
  let response = await fetch(
    `${config.api.base}/worklogs/tag/${tagType}`,
    requestOptions
  );
  return await handleResponse(response);
}

async function batchSave(propertyType, propertyValue, ids) {
  let contentType = { "Content-Type": "application/json" };
  let payload = {
    key: propertyValue,
    value: ids,
  };
  const requestOptions = {
    method: "POST",
    headers: { ...contentType, ...authHeader() },
    body: JSON.stringify(payload),
  };
  let response = await fetch(
    `${config.api.base}/worklogs/edit/${propertyType}`,
    requestOptions
  );
  return await handleResponse(response);
}
