
import { defineComponent } from "vue";
import Basic from "./basic.vue";
import Profitability from "./profitability.vue";
import Retail from "./retail.vue";
import Completions from "./completions.vue";
import Detail from "./detail.vue";
import Recon from "./recon.vue";

export default defineComponent({
  components: { Basic, Profitability, Retail, Completions, Detail, Recon },
  props: {
    scope: {
      type: String,
      default: "",
    },
  },
  setup() {
    return {};
  },
});
