
import { ref, onMounted, onUnmounted } from "vue";

export default {
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
  },
  setup() {
    const container = ref<HTMLElement | null>(null);
    const image = ref<HTMLImageElement | null>(null);

    let isDragging = false;
    let startX = 0;
    let startY = 0;
    let translateX = 0;
    let translateY = 0;
    let scale = 1;

    const reset = () => {
      translateX = 0;
      translateY = 0;
      scale = 1;
      updateTransform();
    };

    const handleMouseDown = (event: MouseEvent) => {
      isDragging = true;
      startX = event.clientX - translateX;
      startY = event.clientY - translateY;
    };

    const handleMouseMove = (event: MouseEvent) => {
      event.preventDefault();
      if (isDragging) {
        translateX = event.clientX - startX;
        translateY = event.clientY - startY;
        updateTransform();
      }
    };

    const handleMouseUp = () => {
      isDragging = false;
    };

    const handleMouseLeave = () => {
      isDragging = false;
    };

    const handleWheel = (event: WheelEvent) => {
      const delta = event.deltaY > 0 ? -0.1 : 0.1;
      scale += delta;
      updateTransform();
    };

    const updateTransform = () => {
      if (container.value && image.value) {
        image.value.style.transform = `translate(${translateX}px, ${translateY}px) scale(${scale})`;
      }
    };

    onMounted(() => {
      if (container.value) {
        container.value.addEventListener("mousedown", handleMouseDown);
        container.value.addEventListener("mousemove", handleMouseMove);
        container.value.addEventListener("mouseup", handleMouseUp);
        container.value.addEventListener("mouseleave", handleMouseLeave);
        container.value.addEventListener("wheel", handleWheel);
      }
    });

    onUnmounted(() => {
      if (container.value) {
        container.value.removeEventListener("mousedown", handleMouseDown);
        container.value.removeEventListener("mousemove", handleMouseMove);
        container.value.removeEventListener("mouseup", handleMouseUp);
        container.value.removeEventListener("mouseleave", handleMouseLeave);
        container.value.removeEventListener("wheel", handleWheel);
      }
    });

    return { container, image, reset };
  },
};
