
import { defineComponent, ref, computed, watch, onMounted } from "vue";
import { isEmpty } from "@/helpers";
import { reportService } from "@/services";
import { dateUS } from "@/filters/index";
import { useToast } from "@/composables/useToast";
import store from "@/store/index";
import DataExplorer from "@/components/workspace/layout/data-explorer.vue";

export default defineComponent({
  name: "ReportBasic",
  components: {
    DataExplorer,
  },
  setup() {
    const { error } = useToast();

    const filterDateRange = computed(
      () => store.getters["context/filterDateRangeForServer"]
    );

    const selected = ref([]);
    const fileLink = ref<HTMLAnchorElement>();
    const loading = ref(false);
    const items = ref([]);
    const headers = [
      { text: "ServiceIn", value: "dateIn" },
      { text: "Emp", value: "employees" },
      { text: "Client", value: "clientCode" },
      { text: "Out", value: "dateOut" },
      { text: "Vin", value: "vin" },
      { text: "Year", value: "year" },
      { text: "Make", value: "make" },
      { text: "Model", value: "model" },
      { text: "Color", value: "color" },
      { text: "StockNumber", value: "stockNumber" },
      { text: "WorkLogCodes", value: "workLogCodes" },
      { text: "Cost", value: "cost" },
      { text: "Billed", value: "billed" },
    ];

    const empty = computed(() => isEmpty(items.value) && !loading.value);

    const init = async () => {
      try {
        loading.value = true;
        items.value = await reportService.get("basic", filterDateRange.value);
      } catch (err) {
        error(err as string);
      } finally {
        loading.value = false;
      }
    };

    const download = (data: { blob: Blob; filename: string }) => {
      const a = fileLink.value as HTMLAnchorElement;
      const url = window.URL.createObjectURL(data.blob);
      a.href = url;
      a.download = data.filename;
      a.click();
    };

    const csv = async () => {
      loading.value = true;
      try {
        let data = await reportService.csv("basic", filterDateRange.value);
        download(data);
      } catch (err) {
        error(err as string);
      }
      loading.value = false;
    };

    const refresh = init;

    watch(filterDateRange, refresh);

    onMounted(init);

    return {
      loading,
      items,
      headers,
      empty,
      csv,
      fileLink,
      dateUS,

      selected,
    };
  },
});
