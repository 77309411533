<template>
  <div>
    <v-toolbar text extended color="white" class="pt-5 white elevation-1">
      <v-text-field
        v-model="vinlocal"
        label="VIN or Stock Number"
        single-line
        clearable
        append-icon="mdi-barcode"
        @click:clear="clear"
        @click:append="scan"
        @keyup.native.enter="fetch()"
      ></v-text-field>
      <v-btn text color="primary" @click="fetch()"> Search </v-btn>
    </v-toolbar>
    <v-progress-linear v-if="loading" :indeterminate="true"></v-progress-linear>
  </div>
</template>

<script>
import { stockService } from "@/services";
export default {
  props: {
    vin: {
      type: String,
      default: "",
    },
  },
  components: {},
  data() {
    return {
      vinlocal: null,
      scanning: false,
      codes: [],
      loading: false,
    };
  },
  async created() {
    await this.init();
  },
  watch: {
    async vin() {
      await this.init();
    },
  },
  methods: {
    async init() {
      if (this.vin) {
        this.vinlocal = this.vinSanitize(this.vin);
        await this.fetch();
      }
    },
    async fetch() {
      if (!this.isValid()) {
        this.$error("Enter at least 4 characters");
        return;
      }
      try {
        this.loading = true;
        var items = await stockService.search(this.vinlocal);
        this.$emit("onSearch", items);
      } catch (error) {
        this.$error(error);
      } finally {
        this.loading = false;
      }
    },
    isValid() {
      var vin = this.vinlocal || "nil";
      return vin !== "nil" && vin.length >= 4;
    },
    async clear() {
      this.$emit("onCancel");
      await this.routerQueryVinValueReset();
    },
    async routerQueryVinValueReset() {
      await this.$wait(1);
      if (this.vin) {
        this.$router.push({ name: "w.search.vin", query: {} });
      }
    },
    iOs() {
      return !!navigator.platform && /iPhone/.test(navigator.platform);
    },
    scan() {
      var href = window.location.href;
      var ptr = href.lastIndexOf("#");
      if (ptr > 0) {
        href = href.substr(0, ptr);
      }
      let encode = encodeURIComponent;
      if (this.iOs()) {
        let retOk = encode(href + "#/w/search/vin?vin={CODE}");
        let retBad = encode(href + "#/w/search/vin");
        let intentUrl = `qrafter://x-callback-url/scan?x-success=${retOk}&x-source=${encode(
          "ray-vehicule"
        )}&x-cancel=${retBad}&browser=external`;
        window.open(intentUrl);
      } else {
        let intentUrl = `zxing://scan/?SCAN_FORMATS=CODE_39&ret=${encode(
          href + "#/w/search/vin?vin={RAWCODE}"
        )}`;
        window.open(intentUrl);
      }
      //<a href="intent://scan/#Intent;scheme=zxing package=com.google.zxing.client.android;end"> Take a QR code </a>
    },
    onScanned(data) {
      this.scanning = false;
      this.vinlocal = data.code;
    },
    vinSanitize(rawCode) {
      let vin;
      let raw = rawCode.trim();
      let l = rawCode.length;
      if (l == 18) {
        vin = raw.substring(1, 9999);
      } else if (l == 19) {
        vin = raw.substring(1, 18);
      } else if (l == 20) {
        vin = raw.substring(2, 19);
      } else {
        vin = raw;
      }
      return vin;
    },
  },
};
</script>

<style></style>
