<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" color="accent" title="Edit Properties of selected items">
        <v-icon left>mdi-square-edit-outline</v-icon>
        Edit ...
      </v-btn>
    </template>
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title class="blue darken-3 py-2 title white--text">
          <v-btn icon dark @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <span>
            Vehicles in Inventory -- ({{ this.items.length }} items selected)
          </span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6>
                <v-select
                  :disabled="!clientSwitch"
                  v-model="clientId"
                  :items="clientsData"
                  item-text="name"
                  item-value="id"
                  label="Client"
                  :rules="clientRules()"
                  required
                  clearable
                  @click:clear="clientId = null"
                ></v-select>
                <v-switch v-model="clientSwitch"></v-switch>
              </v-flex>
              <v-flex xs12 sm6>
                <v-text-field
                  :disabled="!serviceInDateSwitch"
                  v-model="serviceInDate"
                  label="Service In Date"
                  :rules="serviceInDateRules()"
                  type="datetime-local"
                  clearable
                ></v-text-field>
                <v-switch v-model="serviceInDateSwitch"></v-switch>
              </v-flex>
              <v-flex xs12 sm6>
                <date-picker
                  :disabled="!detailInDateSwitch"
                  v-model="detailInDate"
                  label="Detail In Date"
                  :rules="detailInDateRules()"
                />
                <v-switch v-model="detailInDateSwitch"></v-switch>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" color="blue darken-1" text @click="close()"
            >Close</v-btn
          >
          <v-btn
            :disabled="saveButtonDisable()"
            :loading="loading"
            color="blue darken-1"
            text
            @click="save()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
/*
                <date-picker
                  :disabled="!serviceInDateSwitch"
                  v-model="serviceInDate"
                  label="Service In Date"
                  :rules="serviceInDateRules()"
                />

*/
import datePicker from "../layout/date-picker";
import { stockService, clientService } from "@/services";

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    "date-picker": datePicker,
  },
  data: () => ({
    serviceInDate: null,
    detailInDate: null,
    clientId: null,
    dialog: false,
    serviceInDateSwitch: false,
    detailInDateSwitch: false,
    clientSwitch: false,
    loading: false,
    clientsData: [],
    valid: true,
  }),
  async created() {
    await this.init();
  },
  watch: {
    serviceInDateSwitch() {
      this.validReset();
    },
    detailInDateSwitch() {
      this.validReset();
    },
    clientSwitch() {
      this.validReset();
    },
  },
  methods: {
    async init() {
      this.clientsData = await this.fetchClientsData();
    },
    async fetchClientsData() {
      return await clientService.actives();
    },
    clientRules() {
      if (this.clientSwitch) return [(v) => !!v || "Required"];
      else return [];
    },
    serviceInDateRules() {
      if (this.serviceInDateSwitch) return [(v) => !!v || "Required"];
      else return [];
    },
    detailInDateRules() {
      if (this.detailInDateSwitch) return [];
      else return [];
    },
    isValid() {
      return this.$refs.form.validate();
    },
    validReset() {
      this.$refs.form.resetValidation();
    },
    saveButtonDisable() {
      return !(
        this.serviceInDateSwitch ||
        this.clientSwitch ||
        this.detailInDateSwitch
      );
    },
    async save() {
      this.loading = true;
      try {
        if (this.isValid()) {
          await this.clientPost();
          await this.serviceInDatePost();
          await this.detailInDatePost();
          this.$emit("onRefresh");
          this.close();
        }
      } catch (err) {
        this.$error(err);
      }
      this.loading = false;
    },
    close() {
      this.formReset();
      this.dialog = false;
    },
    formReset() {
      this.serviceInDate = null;
      this.detailInDate = null;
      this.clientId = null;
      this.dialog = false;
      this.serviceInDateSwitch = false;
      this.detailInDateSwitch = false;
      this.clientSwitch = false;
    },
    async serviceInDatePost() {
      if (this.serviceInDateSwitch) {
        let itemIds = this.items;
        let propertyType = "serviceInDate";
        let propertyValue = this.serviceInDate;
        await stockService.batchSave(propertyType, propertyValue, itemIds);
      }
    },
    async detailInDatePost() {
      if (this.detailInDateSwitch) {
        let itemIds = this.items;
        let propertyType = "detailInDate";
        let propertyValue = this.detailInDate;
        await stockService.batchSave(propertyType, propertyValue, itemIds);
      }
    },
    async clientPost() {
      if (this.clientSwitch) {
        let itemIds = this.items;
        let propertyType = "client";
        let propertyValue = this.clientId;
        await stockService.batchSave(propertyType, propertyValue, itemIds);
      }
    },
  },
};
</script>

<style></style>
