<template>
  <v-layout row wrap>
    <v-flex v-if="loading" xs12>
      <v-progress-linear :indeterminate="true"></v-progress-linear>
    </v-flex>
    <v-flex v-if="empty">
      <div class="body-1 font-italic">Sorry, nothing to display here :(</div>
    </v-flex>
    <v-flex xs12>
      <v-expansion-panels accordion>
        <v-expansion-panel v-for="item in items" :key="item.id">
          <v-expansion-panel-header>
            <div>
              <div class="title">{{ item.name }}</div>

              <div class="body-1">
                <span> Billed: {{ currency(item.billed) }} </span>
                <span> Vehicles: {{ item.items.length }}</span>
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :mobile-breakpoint="0"
              :headers="headers"
              :items="mapItems(item.items)"
              hide-default-footer
              disable-pagination
            >
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-flex>
  </v-layout>
</template>

<script>
import { listHelper } from "../layout/list-helper";
import { mapGetters } from "vuex";
import { reportService } from "@/services";
import { isEmpty } from "@/helpers";
import { currency } from "@/filters/index";
import { dateUS } from "@/filters/index";

export default {
  name: "profitability",
  data() {
    return {
      loading: false,
      items: [],
      headers: [
        { text: "Service In", value: "serviceInDate", sortable: false },
        { text: "Days", value: "age", sortable: false },
        { text: "VIN", value: "vin", sortable: false },
        { text: "Number", value: "stockNumber", sortable: false },
        { text: "Vehicle", value: "vehicle", sortable: false },
        { text: "Billed", value: "billed", sortable: false },
        { text: "Services", value: "workLogCodes", sortable: false },
      ],
    };
  },
  async created() {
    await this.refresh();
  },
  computed: {
    empty() {
      return isEmpty(this.items) && !this.loading;
    },
    ...mapGetters("context", { filterDateRange: "filterDateRangeForServer" }),
  },
  watch: {
    async filterDateRange() {
      await this.refresh();
    },
  },
  methods: {
    currency,
    dateUS,
    async refresh() {
      this.loading = true;
      try {
        await this.fetchItems();
      } catch (error) {
        this.$error(error);
      }
      this.loading = false;
    },
    async fetchItems() {
      this.items = await reportService.get("retail", this.filterDateRange);
    },
    vehicle(item) {
      return `${item.make} ${item.model} ${item.year}`;
    },
    mapItems(items) {
      return items.map((x) => ({
        serviceInDate: this.dateUS(x.serviceInDate),
        age: x.age,
        vin: x.vin,
        stockNumber: x.stockNumber,
        vehicle: listHelper.vehicle(x),
        billed: this.currency(x.billed),
        workLogCodes: x.workLogCodes,
      }));
    },
  },
};
</script>

<style></style>
