export type NavigationSchemaItem = {
  to?: string;
  group: string;
  icon: string;
  "icon-alt": string;
  text: string;
  params?: { [key: string]: string };
  children?: NavigationSchemaChildrenItem[];
};

export type NavigationSchemaChildrenItem = {
  text: string;
  to: string;
  params?: { [key: string]: string };
};

const technician = [
  {
    group: "main",
    icon: "mdi-view-dashboard",
    "icon-alt": "mdi-view-dashboard",
    text: "Dashboard",
    to: "w.dashboard",
  },
  {
    group: "main",
    icon: "mdi-view-list",
    "icon-alt": "mdi-view-list",
    text: "MyServices",
    to: "w.worklog.scope",
    params: { scope: "employee" },
  },
];

const client = [
  {
    group: "main",
    icon: "mdi-view-dashboard",
    "icon-alt": "mdi-view-dashboard",
    text: "Dashboard",
    to: "w.dashboard",
  },
  {
    group: "stock",
    icon: "mdi-file-document",
    "icon-alt": "mdi-file-document",
    text: "Inventory",
    children: [
      {
        text: "Pending",
        to: "w.stock.scope",
        params: { scope: "pending" },
      },
    ],
  },
  {
    group: "report",
    icon: "mdi-grid",
    "icon-alt": "mdi-grid",
    text: "Reports",
    children: [
      {
        text: "Completions",
        to: "w.report.scope",
        params: { scope: "completions" },
      },
    ],
  },
];

const manager = [
  {
    group: "main",
    icon: "mdi-view-dashboard",
    "icon-alt": "mdi-view-dashboard",
    text: "Dashboard",
    to: "w.dashboard",
  },
  {
    group: "main",
    icon: "mdi-view-list",
    "icon-alt": "mdi-view-list",
    text: "MyServices",
    to: "w.worklog.scope",
    params: { scope: "employee" },
  },
  {
    group: "main",
    icon: "mdi-view-list",
    "icon-alt": "mdi-view-list",
    text: "Pending",
    to: "w.stock.scope",
    params: { scope: "pending" },
  },
  {
    group: "main",
    icon: "mdi-view-list",
    "icon-alt": "mdi-view-list",
    text: "Processed",
    to: "w.worklog.scope",
    params: { scope: "processed" },
  },
  {
    group: "main",
    icon: "mdi-view-list",
    "icon-alt": "mdi-view-list",
    text: "Archives",
    to: "w.stock.scope",
    params: { scope: "archives" },
  },
];

const admin: NavigationSchemaItem[] = [
  {
    group: "main",
    icon: "mdi-view-dashboard",
    "icon-alt": "mdi-view-dashboard",
    text: "Dashboard",
    to: "w.dashboard",
  },
  {
    group: "main",
    icon: "mdi-clipboard-text",
    "icon-alt": "mdi-clipboard-text",
    text: "Batchs",
    to: "w.batchs",
  },
  {
    group: "stock",
    icon: "mdi-file-document",
    "icon-alt": "mdi-file-document",
    text: "Inventory",
    children: [
      {
        text: "Pending",
        to: "w.stock.scope",
        params: { scope: "pending" },
      },
      {
        text: "Processed",
        to: "w.stock.scope",
        params: { scope: "processed" },
      },
      {
        text: "Archives",
        to: "w.stock.scope",
        params: { scope: "archives" },
      },
    ],
  },
  {
    group: "worklog",
    icon: "mdi-view-list",
    "icon-alt": "mdi-view-list",
    text: "Services",
    children: [
      {
        text: "Processed",
        to: "w.worklog.scope",
        params: { scope: "processed" },
      },
      {
        text: "Billed",
        to: "w.worklog.scope",
        params: { scope: "billed" },
      },
      {
        text: "Payroll",
        to: "w.worklog.scope",
        params: { scope: "payroll" },
      },
      {
        text: "Archives",
        to: "w.worklog.scope",
        params: { scope: "archives" },
      },
    ],
  },
  {
    group: "report",
    icon: "mdi-grid",
    "icon-alt": "mdi-grid",
    text: "Reports",
    children: [
      {
        text: "Basic",
        to: "w.report.scope",
        params: { scope: "basic" },
      },
      {
        text: "Profitability",
        to: "w.report.scope",
        params: { scope: "profitability" },
      },
      {
        text: "Retail",
        to: "w.report.scope",
        params: { scope: "retail" },
      },
      {
        text: "Completions",
        to: "w.report.scope",
        params: { scope: "completions" },
      },
      {
        text: "Ready for Detail",
        to: "w.report.scope",
        params: { scope: "detail" },
      },
      {
        text: "Pending Recon",
        to: "w.report.scope",
        params: { scope: "recon" },
      },
    ],
  },
  {
    group: "setting",
    icon: "mdi-cog-outline",
    "icon-alt": "mdi-cog-outline",
    text: "Setting",
    children: [
      { text: "Users", to: "w.setting.users" },
      { text: "Clients", to: "w.setting.clients" },
      { text: "Charges", to: "w.setting.upcharges" },
    ],
  },
];

export { technician, client, manager, admin };
