<template>
  <v-dialog
    v-model="dialog"
    persistent
    :fullscreen="$isPhone"
    :hide-overlay="$isPhone"
    :scrollable="$isPhone"
    :transition="$isPhone ? 'dialog-bottom-transition' : null"
    max-width="600px"
  >
    <v-card flat :class="{ fillHeight: $isPhone }">
      <v-sheet dark class="blue darken-3">
        <div class="d-flex pa-2 align-center">
          <v-btn
            v-if="editing !== 'pictures'"
            icon
            dark
            @click="cancel"
            aria-label="Close edit dialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <div class="title mx-4">{{ title }}</div>
          <v-spacer />
          <v-btn small v-if="editing === 'worklog'" @click="openEditStock()">
            <v-icon small left>mdi-car</v-icon>
            Vehicle
          </v-btn>
        </div>
      </v-sheet>
      <v-progress-linear
        :active="loading"
        :indeterminate="true"
      ></v-progress-linear>
      <stock-edit
        v-model="model"
        :isNew="isNew"
        v-show="editing === 'stock'"
        :key="`stock-xmodel.id`"
        @onSave="onSaveEditStock"
        @onCancel="onCancelEditStock"
      ></stock-edit>
      <worklog-edit
        v-model="model"
        :loading="loading"
        v-show="editing === 'worklog'"
        :key="`worklog-xmodel.id.${refreshKey}`"
        @onOpenPictures="openEditPictures"
        @onSave="onSaveEditWorklog"
        @onCancel="onCancelEditWorklog"
      ></worklog-edit>
      <picture-edit
        v-if="editing === 'pictures'"
        :stockId="model.id"
        :workLogId="pictureWorkLogId"
        :label="`${model.make} ${model.model} ${model.stockNumber}`"
        :key="`pictures-xmodel.id.${refreshKey}`"
        @onSave="refreshPicturesBadge"
        @onCancel="refreshPicturesBadge"
      ></picture-edit>
    </v-card>
  </v-dialog>
</template>

<script>
import { guid, dateZ } from "@/helpers";
import { stockService } from "@/services";
import StockEdit from "./stock-edit";
import WorklogEdit from "./worklog-edit";
import PictureEdit from "./picture-edit";

export default {
  props: {
    value: { type: Boolean, default: false },
    id: {
      type: String,
      default: "none",
    },
    vin: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      localId: null,
      refreshKey: null,
      editing: "worklog",
      pictureWorkLogId: null,
      isNew: false,
      loading: false,
      model: {
        id: null,
        clientId: "",
        stockNumber: "",
        vin: "",
        make: "",
        model: "",
        year: "",
        color: "",
        miles: "",
        comments: "",
        serviceInDate: "",
        detailInDate: "",
        archived: false,
        workLogsDTS: [],
        pictureIds: [],
      },
    };
  },
  components: {
    StockEdit,
    WorklogEdit,
    PictureEdit,
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      async set(val) {
        this.$emit("input", val);
      },
    },
    title() {
      const stock = "Vehicle in Inventory";
      const service = "Services";
      const pictures = "Pictures";
      return this.editing === "worklog"
        ? service
        : this.editing === "pictures"
        ? pictures
        : stock;
    },
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.refreshKey = guid();
        this.init();
      }
    },
  },
  methods: {
    async init() {
      let hasId = !!this.id;
      this.isNew = (this.id || "").startsWith("new");
      try {
        this.loading = true;
        if (this.isNew) {
          this.editing = "stock";
          this.model = await this.newStock(this.vin);
        } else if (hasId) {
          this.editing = "worklog";
          this.model = await stockService.get(this.id);
        }
      } catch (error) {
        this.$error(error);
      } finally {
        this.loading = false;
      }
    },
    async newStock(vin) {
      let today = dateZ.formatISO(dateZ.today());
      let vehicleData = await this.fetchVehicleDataByVin(vin);
      let vehicleNew = {
        ...vehicleData,
        ...{
          id: guid(),
          vin: vin,
          serviceInDate: today,
          detailInDate: today,
          workLogsDTS: [],
        },
      };
      return vehicleNew;
    },
    async fetchVehicleDataByVin(vin) {
      if (!vin) {
        return {};
      }
      return await stockService.vin(vin);
    },
    openEditStock() {
      this.editing = "stock";
    },
    openEditPictures(workLogId) {
      this.pictureWorkLogId = workLogId;
      this.editing = "pictures";
    },
    refreshPicturesBadge(pictureCount) {
      const worklogs = this.model.workLogsDTS;
      const worklog = worklogs.find((x) => x.id === this.pictureWorkLogId);
      if (worklog) {
        worklog.fileAttachmentCount = pictureCount;
        const index = this.model.workLogsDTS.findIndex(
          (x) => x.id === this.pictureWorkLogId
        );
        if (index !== -1) {
          this.model.workLogsDTS.splice(index, 1, worklog);
        }
      }
      this.editing = "worklog";
    },
    async onSaveEditStock() {
      try {
        this.loading = true;
        await stockService.put(this.model);
      } catch (error) {
        this.$error(error);
      } finally {
        this.loading = false;
      }
      this.editing = "worklog";
    },
    onCancelEditStock() {
      if (this.isNew) {
        this.$emit("onCancel");
      } else {
        this.editing = "worklog";
      }
    },
    onCancelEditWorklog() {
      this.$emit("onCancel");
    },
    cancel() {
      if (this.editing === "stock") {
        this.onCancelEditStock();
      } else if (this.editing === "pictures") {
        this.onCancelEditPicture();
      } else {
        this.onCancelEditWorklog();
      }
    },
    async onSaveEditWorklog() {
      try {
        this.loading = true;
        await stockService.saveWorklogs(this.model);
        this.$emit("onSave");
      } catch (error) {
        this.$error(error);
      } finally {
        this.loading = false;
        this.dialog = false;
      }
    },
    reset() {
      this.localId = null;
      this.editing = "worklog";
    },
  },
};
</script>

<style></style>
