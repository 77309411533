<template>
  <v-row>
    <v-col cols="12">
      <div class="d-flex justify-end ray-bar">
        <v-btn color="accent" @click="openNewForm()">
          <v-icon left>mdi-domain</v-icon>
          New client ...
        </v-btn>
        <v-btn color="error" @click="deleteIt()">
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </div>
    </v-col>
    <v-col cols="12">
      <client-list
        ref="clientsListElement"
        :items="items"
        :loading="loading"
        @onItemSelect="openEditForm"
      />
    </v-col>
    <client-edit
      v-model="selectedItemForm"
      :item="selectedItem"
      @onSave="onEditSave"
    />
  </v-row>
</template>

<script>
import clientsList from "./list";
import clientEdit from "./edit";
import { clientService } from "@/services";
import { guid } from "@/helpers";

export default {
  data() {
    return {
      items: [],
      selectedItem: {},
      selectedItemForm: false,
      loading: false,
    };
  },
  async created() {
    await this.fetchItems();
  },
  beforeRouteLeave(to, from, next) {
    let anyDialogShow = this.$confirm.isShow();
    if (anyDialogShow) {
      this.selectedItemForm = false;
      this.$confirm.cancel();
      next(false);
    } else {
      next();
    }
  },
  components: {
    "client-list": clientsList,
    "client-edit": clientEdit,
  },
  methods: {
    async fetchItems() {
      this.loading = true;
      try {
        this.items = await clientService.all();
      } catch (error) {
        this.$error(error);
      }
      this.loading = false;
    },
    openEditForm(item) {
      this.selectedItem = item;
      this.selectedItemForm = true;
    },
    async onEditSave(item) {
      try {
        await clientService.save(item);
        this.items = await clientService.all();
      } catch (error) {
        this.$error(error);
      }
    },
    async deleteIt() {
      if (!this.isValid()) {
        return;
      }
      let ok = await this.$confirm.show("Confirm to delete selected items?");
      if (!ok) {
        return;
      }
      await this.tag("delete");
    },
    async tag(tagType) {
      try {
        let ids = this.selected();
        await clientService.tag(tagType, ids);
        this.items = await clientService.all();
      } catch (error) {
        this.$error(error);
      }
    },
    selected() {
      let items = this.$refs.clientsListElement.selected;
      let ids = items.map((x) => x.id);
      return ids;
    },
    openNewForm() {
      this.selectedItem = {
        id: guid(),
        active: true,
      };
      this.selectedItemForm = true;
    },
    isValid() {
      if (this.selected.length == 0) {
        this.$error("No items selected.");
        return false;
      }
      return true;
    },
  },
};
</script>

<style></style>
