
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router/composables";
import {
  NavigationSchemaItem,
  NavigationSchemaChildrenItem,
  admin,
  client,
  manager,
  technician,
} from "./navigation-schema";
import store from "@/store/index";

export default defineComponent({
  name: "NavigationDrawer",
  components: {},
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const roles = computed(() => store.getters["authentication/role"]);

    const drawerInternal = computed({
      get() {
        return props.drawer;
      },
      set(v) {
        emit("update:drawer", v);
      },
    });

    const activeItem = ref("");
    const items = ref<NavigationSchemaItem[]>([]);

    const init = () => {
      if (roles.value.isAdmin) {
        items.value = admin;
      } else if (roles.value.isManager) {
        items.value = manager;
      } else if (roles.value.isClient) {
        items.value = client;
      } else if (roles.value.isTechnician) {
        items.value = technician;
      } else {
        logout();
      }
    };

    const isItemActive = (
      item: NavigationSchemaItem | NavigationSchemaChildrenItem
    ) => {
      return activeItem.value === itemKey(item.to, item.params);
    };

    const itemKey = (
      to: string | undefined,
      params: { [key: string]: string } | undefined
    ) => {
      if (to === undefined) {
        return "";
      }
      let params1 = { ...params };
      return `${to}.${params1.scope || "none"}`;
    };

    const routeTo = (
      name: string | undefined,
      params: { [key: string]: string } | undefined
    ) => {
      if (activeItem.value === itemKey(name, params)) {
        return;
      }
      activeItem.value = itemKey(name, params);
      router.push({ name, params }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    };

    const logout = () => {
      router.push({ name: "login" });
    };

    const dashboard = () => {
      router.push({ name: "w.dashboard" });
    };

    onMounted(init);

    return {
      drawerInternal,
      dashboard,
      logout,
      items,
      itemKey,
      isItemActive,
      routeTo,
    };
  },
});
