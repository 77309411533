<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    persistent
    :fullscreen="$isPhone"
    :hide-overlay="$isPhone"
    :scrollable="$isPhone"
    :transition="$isPhone ? 'dialog-bottom-transition' : null"
  >
    <v-card>
      <v-card-title
        class="blue darken-3 py-2 title white--text"
        style="max-height: 60px"
      >
        <v-btn icon dark @click="cancel()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <span> Upcharge Edit </span>
        <v-spacer />
      </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text class="white">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm8>
                <v-text-field
                  v-model="model.name"
                  label="Upcharge Name*"
                  required
                  :rules="[(v) => !!v || 'Name is required']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm4>
                <v-text-field
                  v-model="model.code"
                  label="Upcharge Code*"
                  required
                  :rules="[(v) => !!v || 'Code is required']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field
                  v-model="model.price"
                  label="Price*"
                  required
                  :rules="numberRules"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field
                  v-model="model.cost"
                  label="Cost*"
                  required
                  :rules="numberRules"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4> </v-flex>
              <v-flex xs12 sm6 md4>
                <v-checkbox v-model="model.active" label="Active"></v-checkbox>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-checkbox
                  v-model="model.isUpCharge"
                  label="Is Upcharge"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field
                  v-model="model.printOrder"
                  label="Print Order"
                  :rules="integerRules"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions class="white">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel()">Close</v-btn>
          <v-btn color="blue darken-1" text @click="ok()">Save</v-btn>
        </v-card-actions>
        <div></div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    model: {
      name: "",
      code: "",
      printOrder: "",
      cost: "",
      price: "",
      active: true,
      isUpCharge: false,
    },
    valid: false,
    integerRules: [(v) => /^\d*$/.test(v) || "Number Integer(ex. 1,2...1000)"],
    numberRules: [
      (v) => !!v || "Required",
      (v) => /^\d*\.?\d{0,2}$/.test(v) || "Number (ex. 15.50)",
    ],
  }),
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  watch: {
    "item.id": function () {
      this.model = this.item;
    },
  },
  methods: {
    isValid() {
      return this.$refs.form.validate();
    },
    ok() {
      if (this.isValid()) {
        this.dialog = false;
        this.$emit("onSave", this.model);
      }
    },
    cancel() {
      this.dialog = false;
      this.$emit("onCancel");
    },
  },
};
</script>
