import { config } from "@/config";
import { authenticationService } from "./authentication.service";
let { authHeader, handleResponse, handleFileDownloadResponse } =
  authenticationService;

export const stockService = {
  all,
  get,
  search,
  vin,
  saveWorklogs,
  put,
  tag,
  batchSave,
  csv,
};

async function all(scope, scopeId, range) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const parmScopeId = scopeId ? `scopeId=${scopeId}` : "";
  let response = await fetch(
    `${config.api.base}/stocks/scope/${scope}/${range}?${parmScopeId}`,
    requestOptions
  );
  return await handleResponse(response);
}

async function get(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(`${config.api.base}/stocks/${id}`, requestOptions);
  return await handleResponse(response);
}

async function search(vinOrStockNumber) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(
    `${config.api.base}/stocks/search/${vinOrStockNumber}`,
    requestOptions
  );
  return await handleResponse(response);
}

async function vin(vin) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(
    `${config.api.base}/stocks/vin/${vin}`,
    requestOptions
  );
  return await handleResponse(response);
}

async function saveWorklogs(stock) {
  let contentType = { "Content-Type": "application/json" };
  const requestOptions = {
    method: "PUT",
    headers: { ...contentType, ...authHeader() },
    body: JSON.stringify(stock),
  };

  let response = await fetch(
    `${config.api.base}/stocks/worklog/${stock.id}`,
    requestOptions
  );
  return await handleResponse(response);
}

async function put(stock) {
  let contentType = { "Content-Type": "application/json" };
  const requestOptions = {
    method: "PUT",
    headers: { ...contentType, ...authHeader() },
    body: JSON.stringify(stock),
  };

  let response = await fetch(
    `${config.api.base}/stocks/${stock.id}`,
    requestOptions
  );
  return await handleResponse(response);
}

async function tag(tagType, ids) {
  let contentType = { "Content-Type": "application/json" };
  const requestOptions = {
    method: "POST",
    headers: { ...contentType, ...authHeader() },
    body: JSON.stringify(ids),
  };
  let response = await fetch(
    `${config.api.base}/stocks/tag/${tagType}`,
    requestOptions
  );
  return await handleResponse(response);
}

async function batchSave(propertyType, propertyValue, ids) {
  let contentType = { "Content-Type": "application/json" };
  let payload = {
    key: propertyValue,
    value: ids,
  };
  const requestOptions = {
    method: "POST",
    headers: { ...contentType, ...authHeader() },
    body: JSON.stringify(payload),
  };
  let response = await fetch(
    `${config.api.base}/stocks/edit/${propertyType}`,
    requestOptions
  );
  return await handleResponse(response);
}

async function csv(fileType, ids) {
  let contentType = { "Content-Type": "application/json" };
  const requestOptions = {
    method: "POST",
    headers: { ...contentType, ...authHeader() },
    body: JSON.stringify(ids),
  };
  let response = await fetch(
    `${config.api.base}/stocks/csv/${fileType}`,
    requestOptions
  );
  return await handleFileDownloadResponse(response);
}
