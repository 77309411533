import { ref, computed, ComputedRef } from "vue";

type ToastColor = "success" | "error" | "secondary" | "grey";

const state = {
  message: ref(""),
  color: ref<ToastColor>("secondary"),
};

const getters = {
  message: computed(() => state.message.value),
  color: computed(() => state.color.value),
};

function actions() {
  const toast = (message: string) => {
    state.message.value = message;
    state.color.value = "grey";
  };
  const error = (message: string) => {
    state.message.value = message;
    state.color.value = "error";
  };
  const clear = () => {
    state.message.value = "";
  };
  return { toast, error, clear };
}

export function useToast(): {
  toast: (message: string) => void;
  error: (message: string) => void;
  clear: () => void;
  getters: {
    message: ComputedRef<string>;
    color: ComputedRef<ToastColor>;
  };
} {
  return { getters, ...actions() };
}
