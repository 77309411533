<template>
  <v-row dense>
    <v-col cols="12">
      <div
        v-if="scope == 'archives' && $role.isAdmin"
        class="d-flex justify-end ray-bar"
      >
        <v-btn
          v-if="anyItemSelected"
          color="accent"
          @click="csv('basic')"
          title="Export selected items"
        >
          <v-icon left>mdi-download-multiple</v-icon>
          Export CSV
        </v-btn>
        <v-btn
          :disabled="!anyItemSelected"
          color="accent"
          @click="onButtonTag('unarchive')"
          title="Unarchive selected items"
        >
          <v-icon left>mdi-package-up</v-icon>
          Unarchive
        </v-btn>
        <v-btn color="accent" @click="refresh()" title="Refresh">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </div>
      <div
        v-else-if="$role.isClient && scope != 'archives'"
        class="d-flex justify-end ray-bar"
      >
        <v-btn
          v-if="anyItemSelected"
          color="accent"
          @click="onButtonDetailIn"
          title="Detail in now"
        >
          <v-icon left>mdi-key-plus</v-icon>
          Detail In
        </v-btn>
      </div>
      <div
        v-else-if="scope != 'archives'"
        text
        color="white"
        class="d-flex justify-end ray-bar"
      >
        <v-btn color="accent" @click="openNewItem()">
          <v-icon left>mdi-car</v-icon>
          Add Vehicle ...
        </v-btn>
        <v-btn
          v-if="anyItemSelected && $role.isAdmin"
          color="accent"
          @click="onButtonTag('archive')"
          title="Archive selected items"
        >
          <v-icon left>mdi-package-down</v-icon>
          Archive
        </v-btn>
        <v-btn
          v-if="anyItemSelected && $role.isAdmin"
          color="accent"
          @click="csv('basic')"
          title="Export selected items"
        >
          <v-icon left>mdi-download-multiple</v-icon>
          Export CSV
        </v-btn>
        <v-btn
          v-if="anyItemSelected"
          color="accent"
          @click="onButtonDetailIn"
          title="Detail in now"
        >
          <v-icon left>mdi-key-plus</v-icon>
          Detail In
        </v-btn>
        <BatchEdit
          v-if="selectedItemIds.length > 1"
          :items="selectedItemIds"
          @onRefresh="refresh"
        >
        </BatchEdit>
        <v-btn
          v-else-if="selectedItemIds.length === 1"
          color="accent"
          title="Edit Properties of selected items"
          @click="onBtnOpenEditItem()"
        >
          <v-icon left>mdi-square-edit-outline</v-icon>
          Edit ...
        </v-btn>
        <v-btn
          v-if="anyItemSelected"
          color="error"
          @click="onButtonTag('delete')"
          title="Delete selected items"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn color="accent" @click="refresh()" title="Refresh">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </div>
    </v-col>
    <v-col cols="12">
      <StockList
        ref="StocksListElement"
        :items="items"
        :loading="loading"
        @onSelectItems="onSelectItems"
        @onClickRow="openEditItem"
        @onClickVehicleKeyIn="onButtonVehicleKeyIn"
      ></StockList>
      <item-edit
        v-model="dialogEditItem"
        :key="`item-edit-dialog-${itemEditRefreshKey}`"
        :id="selectedEditItem"
        @onSave="onSaveWorklogEdit"
        @onCancel="onCancelEditItem"
      />
      <a style="display: none" ref="fileLink">dummy</a>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { stockService } from "@/services";
import { dateZ } from "@/helpers";
import StockList from "./list";
import ItemEdit from "./edit";
import BatchEdit from "./batch-edit";

export default {
  name: "StockExplorer",
  props: {
    scope: {
      type: String,
      default: "",
    },
    scopeId: {
      type: String,
      default: "",
    },
  },
  components: {
    ItemEdit,
    StockList,
    BatchEdit,
  },
  data() {
    return {
      items: [],
      loading: false,
      selectedEditItem: null,
      dialogEditItem: false,
      selectedItemIds: [],
      itemEditRefreshKey: 1,
    };
  },
  async created() {
    await this.refresh();
  },
  computed: {
    anyItemSelected() {
      return this.selectedItemIds.length > 0;
    },
    ...mapGetters("context", { filterDateRange: "filterDateRangeForServer" }),
  },
  watch: {
    async scope() {
      await this.refresh();
    },
    async filterDateRange() {
      await this.refresh();
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.routerGuard(next);
  },
  beforeRouteLeave(to, from, next) {
    this.routerGuard(next);
  },
  methods: {
    routerGuard(next) {
      let confirm = this.$confirm.isShow();
      if (this.dialogEditItem || confirm) {
        this.dialogEditItem = false;
        this.$confirm.cancel();
        next(false);
      } else {
        next();
      }
    },
    async refresh() {
      this.loading = true;
      try {
        await this.fetchItems();
      } catch (error) {
        this.$error(error);
      }
      this.loading = false;
      this.itemEditRefreshKey++;
    },
    async fetchItems() {
      this.items = await stockService.all(
        this.scope,
        this.scopeId,
        this.filterDateRange
      );
    },
    async onButtonDetailIn() {
      let itemIds = this.selectedItemIds;
      this.loading = true;
      try {
        let propertyType = "detailInDate";
        let propertyValue = dateZ.formatISO(new Date());
        await stockService.batchSave(propertyType, propertyValue, itemIds);
        this.$toast("Successfully saved");
        await this.fetchItems();
      } catch (error) {
        this.$error(error);
      }
      this.loading = false;
    },
    async onButtonVehicleKeyIn(resolve, id) {
      let itemIds = [id];
      this.loading = true;
      try {
        let propertyType = "vehicleKeyInDate";
        let propertyValue = dateZ.formatISO(new Date());
        await stockService.batchSave(propertyType, propertyValue, itemIds);
        await this.fetchItems();
        resolve();
      } catch (error) {
        this.$error(error);
      }
      this.loading = false;
    },
    onSelectItems(ids) {
      this.selectedItemIds = ids;
    },
    async onButtonTag(tagType) {
      if (!this.isValid()) {
        return false;
      }
      if (!(await this.confirmBeforeTag(tagType))) {
        return;
      }
      await this.tag(tagType);
    },
    async confirmBeforeTag(tagType) {
      let warnings = {
        delete: { title: "Confirm to delete selected items?" },
        archive: { title: "Confirm to archive selected items?" },
        unarchive: {
          title: "Confirm to unarchive selected items?",
          text: `Selected items will be activate and send to the "Processing" bucket. (Under menu option - Stock/Processing).`,
        },
      };
      let title = warnings[tagType].title;
      let text = warnings[tagType].text;
      return await this.$confirm.show(title, text);
    },
    async tag(tagType) {
      let ids = this.selectedItemIds;
      this.loading = true;
      try {
        await stockService.tag(tagType, ids);
        await this.fetchItems();
      } catch (error) {
        this.$error(error);
      }
      this.loading = false;
    },
    async onSaveWorklogEdit() {
      try {
        await this.fetchItems();
      } catch (error) {
        this.$error(error);
      }
    },
    onCancelEditItem() {
      this.dialogEditItem = false;
    },
    openEditItem(itemId) {
      this.selectedEditItem = itemId;
      this.dialogEditItem = true;
    },
    openNewItem() {
      this.selectedEditItem = `new-${this.$random(1, 999)}`;
      this.dialogEditItem = true;
    },
    onBtnOpenEditItem() {
      this.selectedEditItem = this.selectedItemIds[0];
      this.dialogEditItem = true;
    },
    isValid() {
      let ids = this.selectedItemIds;
      if (ids.length == 0) {
        this.$error("No items selected.");
        return false;
      }
      return true;
    },
    async csv(csvType) {
      this.loading = true;
      let ids = this.selectedItemIds;
      try {
        let data = await stockService.csv(csvType, ids);
        this.download(data);
      } catch (error) {
        this.$error(error);
      }
      this.loading = false;
    },
    download(data) {
      var a = this.$refs.fileLink;
      var url = window.URL.createObjectURL(data.blob);
      a.href = url;
      a.download = data.filename;
      a.click();
    },
  },
};
</script>

<style></style>
