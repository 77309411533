import { dateZ } from "@/helpers";

const today = dateZ.today();

export const context = {
  namespaced: true,
  state: {
    visibility: true,
    DateRangeDefault: "today",
    DateRangeData: [
      { text: "Previous Year", value: "Previous Year" },
      { text: "This Year", value: "This Year" },
      { text: "Previous Month", value: "Previous Month" },
      { text: "This Month", value: "This Month" },
      { text: "Previous Week", value: "Previous Week" },
      { text: "This Week", value: "This Week" },
      { text: "Yesterday", value: "Yesterday" },
      { text: "Today", value: "Today" },
    ],
    DateRangeSelected: "today",
    DateRangeValues: {
      start: new Date(today.valueOf()),
      end: new Date(today.valueOf()),
    },
  },
  getters: {
    filterDateRangeForServer(state) {
      let range = state.DateRangeValues;
      let start = dateZ.format(range.start, "yyyy-MM-dd");
      let end = dateZ.format(range.end, "yyyy-MM-dd");

      return `${start},${end}`;
    },
  },
  actions: {
    visibility({ commit }, payload) {
      commit("visibility", payload);
    },
  },
  mutations: {
    filterDateRange(state, payload) {
      let d = {
        start: new Date(payload.start.valueOf()),
        end: new Date(payload.end.valueOf()),
      };
      state.DateRangeValues = d;
    },
    visibility(state, payload) {
      state.visibility = payload;
    },
  },
};
