<template>
  <div>
    <v-card-text class="white">
      <edit-header v-model="model" v-if="!loading" />
      <v-list
        v-if="model.workLogsDTS.length > 0 && !loading"
        subheader
        two-line
        class="mb-2 grey lighten-3"
      >
        <v-subheader>Services list</v-subheader>
        <template v-for="(item, index) in model.workLogsDTS">
          <v-list-item :key="item.id">
            <v-list-item-content>
              <v-list-item-title :class="{ deleted: item.deleted }">
                {{ item.upChargeName }}
                <span class="text--secondary body-2"
                  >&mdash; {{ dateTimeUS(item.upChargeDate) }}</span
                >
                <v-chip
                  label
                  small
                  v-show="item.billed"
                  color="accent"
                  text-color="white"
                  class="ml-3"
                  title="Billed"
                  >B</v-chip
                >
                <v-chip
                  v-show="item.inPayroll"
                  color="info"
                  text-color="white"
                  label
                  small
                  title="In Payroll"
                  >P</v-chip
                >
                <v-chip
                  v-show="item.archived"
                  color="grey darken-3"
                  text-color="white"
                  label
                  small
                  title="Archived"
                  >A</v-chip
                >
              </v-list-item-title>
              <v-list-item-subtitle :class="{ deleted: item.deleted }">
                <span class="text--primary">{{ item.assignedToName }}</span>
                <span>&mdash; {{ `Qty: ${item.quantity}` }}</span>
                <span v-show="item.note">&mdash; {{ item.note }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <div class="d-flex">
                <v-badge
                  class="mr-4"
                  color="error"
                  :content="item.fileAttachmentCount"
                  :value="item.fileAttachmentCount > 0"
                  overlap
                >
                  <v-btn large icon color="primary" @click="openPicture(item)">
                    <v-icon>mdi-camera</v-icon>
                  </v-btn>
                </v-badge>
                <v-btn
                  v-if="item.deleted"
                  icon
                  color="blue"
                  @click="restore(item)"
                >
                  <v-icon>mdi-restore</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  icon
                  color="red"
                  @click="remove(item)"
                  :disabled="item.archived"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </v-list-item-action>
          </v-list-item>
          <v-divider
            v-if="index + 1 < model.workLogsDTS.length"
            :key="index"
          ></v-divider>
        </template>
      </v-list>
      <upcharge-form
        v-if="!readonly"
        ref="UpchargesForm"
        @onAdd="add"
      ></upcharge-form>
      <div v-show="!valid" class="error--text caption">
        Information pending to add. Press add button (+) or (x) to dismiss it.
      </div>

      <div class="d-flex mt-2">
        <div class="font-italic grey--text">
          {{
            readonly ? "This Item is archived. Modification not allowed." : ""
          }}
        </div>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel()">Cancel</v-btn>
        <v-btn
          :loading="loading"
          :disabled="readonly || loading"
          text
          color="blue darken-1"
          @click="save()"
        >
          Save
          <template v-slot:loader>
            <span>Wait ...</span>
          </template>
        </v-btn>
      </div>
    </v-card-text>
  </div>
</template>

<script>
import EditHeader from "./worklog-edit-header";
import UpchargeForm from "./worklog-edit-upcharge";
import { dateTimeUS } from "@/filters/index";

export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          workLogsDTS: [],
        };
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EditHeader,
    UpchargeForm,
  },
  data() {
    return {
      valid: true,
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    readonly() {
      return this.model.archived;
    },
  },
  methods: {
    dateTimeUS,
    remove(item) {
      item.deleted = true;
    },
    restore(item) {
      item.deleted = false;
    },
    add(charge) {
      this.model.workLogsDTS.push(charge);
    },
    isValid() {
      this.valid = this.$refs.UpchargesForm.isClear();
      return this.valid;
    },
    openPicture(item) {
      this.$emit("onOpenPictures", item.id);
    },
    async cancel() {
      if (!this.readonly) {
        await this.$refs.UpchargesForm.clear();
      }
      this.$emit("onCancel");
    },
    async save() {
      if (!this.isValid()) {
        return;
      }
      this.$emit("onSave");
    },
  },
};
</script>

<style scoped>
.deleted {
  text-decoration: line-through;
}
.v-chip {
  padding: 0 4px;
}
.v-chip--label {
  border-radius: 0px !important;
}
</style>
