<template>
  <div class="d-flex justify-end ray-bar">
    <payroll-export-btn :selectedItemIds="selectedItemIds" />
    <v-btn
      v-if="anyItemSelected"
      color="accent"
      @click="csv('accounting')"
      title="Export selected items"
    >
      <v-icon left>mdi-download-multiple</v-icon>
      Export CSV
    </v-btn>
    <v-btn color="accent" @click="unarchive()" title="Unarchive selected items">
      <v-icon left>mdi-package-up</v-icon>
      Unarchive
    </v-btn>
    <a style="display: none" ref="fileLink">dummy</a>
  </div>
</template>

<script>
import PayrollExportBtn from "@/components/workspace/worklog/payroll-export-btn.vue";
import { worklogService } from "@/services";

export default {
  components: {
    PayrollExportBtn,
  },
  props: {
    selectedItemIds: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    dialog: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  computed: {
    loadingLocal: {
      get() {
        return this.loading;
      },
      set(val) {
        this.$emit("update:loading", val);
      },
    },
    anyItemSelected() {
      return this.selectedItemIds.length > 0;
    },
    confirmUnarchive() {
      return this.$confirm.isShow();
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.$confirm.cancel();
      }
    },
    confirmUnarchive(val) {
      this.$emit("update:dialog", val);
    },
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
    async confirm() {
      let title = "Confirm to unarchive selected items?";
      let text = `Selected items will be activate and send to the "Processing" bucket. (Under menu option - Stock/Processing).`;
      let ok = await this.$confirm.show(title, text);
      return ok;
    },
    async unarchive() {
      if (!this.isValid()) {
        return false;
      }
      if (!(await this.confirm())) {
        return false;
      }
      await this.tag("unarchive");
    },
    download(data) {
      var a = this.$refs.fileLink;
      var url = window.URL.createObjectURL(data.blob);
      a.href = url;
      a.download = data.filename;
      a.click();
    },
    isValid() {
      let ids = this.selectedItemIds;
      if (ids && ids.length > 0) {
        return true;
      } else {
        this.$error("No items selected.");
        return false;
      }
    },
    async csv(csvType) {
      this.loadingLocal = true;
      let ids = this.selectedItemIds;
      try {
        let data = await worklogService.csv(csvType, ids);
        this.download(data);
      } catch (error) {
        this.$error(error);
      }
      this.loadingLocal = false;
    },
    async tag(tagType) {
      this.loadingLocal = true;
      let ids = this.selectedItemIds;
      try {
        await worklogService.tag(tagType, ids);
      } catch (error) {
        this.$error(error);
      } finally {
        this.loadingLocal = false;
      }
      this.refresh();
    },
  },
};
</script>
