<template>
  <v-snackbar v-model="show" :color="color">
    {{ text }}
    <v-btn text @click.native="show = false">Close</v-btn>
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "toast",
  data() {
    return {
      show: false,
      color: "accent",
      text: "",
    };
  },
  computed: {
    ...mapState("alert", ["message", "type"]),
  },
  watch: {
    message: function (val) {
      let msg = val || "";
      if (msg !== "") {
        this.color = this.type;
        this.show = true;
        this.text = msg;
        this.$store.commit("alert/clear", "");
      }
    },
  },
};
</script>
