var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":true}}):_vm._e()],1),(!_vm.loading)?[(_vm.$role.isManager || _vm.$role.isClient)?_c('card-number',{attrs:{"value":_vm.model.stockPending,"format":{
        icon: 'mdi-car',
        color: 'error lighten-4',
        title: 'Vehicles in Inventory',
        text: 'pending',
      }},on:{"onClick":function($event){return _vm.$router.push({
          name: 'w.stock.scope',
          params: { scope: 'pending' },
        })}}}):_vm._e(),(_vm.$role.isManager)?_c('card-number',{attrs:{"value":_vm.model.stockCompleted,"format":{
        icon: 'mdi-car',
        color: 'success lighten-4',
        title: 'Vehicles in Inventory',
        text: 'processed',
      }},on:{"onClick":function($event){return _vm.toWorklog()}}}):_vm._e(),(!_vm.$role.isClient)?[_c('card-number',{attrs:{"value":_vm.model.upChargesCompleted,"format":{
          icon: 'mdi-checkbox-marked-circle-outline',
          color: 'success lighten-4',
          title: 'Services',
          text: 'done',
        }},on:{"onClick":function($event){return _vm.toWorklog()}}}),_vm._l((_vm.model.upChargesSummary),function(upcharge){return _c('card-number',{key:upcharge.name,attrs:{"value":upcharge.count,"format":_vm.upChargeFormat(upcharge)},on:{"onClick":function($event){return _vm.toWorklog()}}})})]:_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }