<template>
  <v-row>
    <v-col cols="12">
      <v-progress-linear
        v-if="loading"
        :indeterminate="true"
      ></v-progress-linear>
    </v-col>
    <template v-if="!loading">
      <card-number
        v-if="$role.isManager || $role.isClient"
        :value="model.stockPending"
        :format="{
          icon: 'mdi-car',
          color: 'error lighten-4',
          title: 'Vehicles in Inventory',
          text: 'pending',
        }"
        @onClick="
          $router.push({
            name: 'w.stock.scope',
            params: { scope: 'pending' },
          })
        "
      />
      <card-number
        v-if="$role.isManager"
        :value="model.stockCompleted"
        :format="{
          icon: 'mdi-car',
          color: 'success lighten-4',
          title: 'Vehicles in Inventory',
          text: 'processed',
        }"
        @onClick="toWorklog()"
      />
      <template v-if="!$role.isClient">
        <card-number
          :value="model.upChargesCompleted"
          :format="{
            icon: 'mdi-checkbox-marked-circle-outline',
            color: 'success lighten-4',
            title: 'Services',
            text: 'done',
          }"
          @onClick="toWorklog()"
        />
        <card-number
          v-for="upcharge in model.upChargesSummary"
          :key="upcharge.name"
          :value="upcharge.count"
          :format="upChargeFormat(upcharge)"
          @onClick="toWorklog()"
        />
      </template>
    </template>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import { dashboardService } from "@/services";
import cardNumber from "./card-number";

export default {
  components: {
    "card-number": cardNumber,
  },
  data: () => ({
    model: {},
    loading: false,
  }),
  computed: {
    ...mapGetters("context", { filterDateRange: "filterDateRangeForServer" }),
  },
  watch: {
    async filterDateRange() {
      await this.refresh();
    },
  },
  async created() {
    await this.refresh();
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        this.model = await dashboardService.get(this.filterDateRange);
      } catch (error) {
        this.$error(error);
      } finally {
        this.loading = false;
      }
    },
    upChargeFormat(item) {
      return {
        icon: "mdi-checkbox-marked-circle-outline",
        color: "info lighten-4",
        title: `Service ${item.name}`,
        text: "done",
      };
    },
    async refresh() {
      await this.fetchData();
    },
    toWorklog() {
      if (this.$role.isManager)
        this.$router.push({
          name: "w.worklog.scope",
          params: { scope: "processed" },
        });
      else
        this.$router.push({
          name: "w.worklog.scope",
          params: { scope: "employee" },
        });
    },
  },
};
</script>
