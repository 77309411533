import { config } from "@/config";
import { authenticationService } from "./authentication.service";

export const pictureService = () => {
  const { authHeader, handleResponse } = authenticationService;

  async function getFileIds(masterId: string, worklogId: string) {
    const headers = authHeader();
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json", ...headers },
    };
    const response = await fetch(
      `${config.api.base}/fileAttachment?masterId=${masterId}&worklogId=${worklogId}`,
      requestOptions
    );
    return await handleResponse(response);
  }

  async function upload(masterId: string, files: File[]) {
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append("files", file);
    }

    const requestOptions = {
      method: "POST",
      body: formData,
      headers: authHeader(),
    };

    const response = await fetch(
      `${config.api.base}/fileAttachment/multiples/${masterId}`,
      requestOptions
    );
    return await handleResponse(response);
  }

  async function deleteFile(fileIds: string[]) {
    const auth = authHeader();
    const payload = fileIds;
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json", ...auth },
      body: JSON.stringify(payload),
    };
    const response = await fetch(
      `${config.api.base}/fileAttachment`,
      requestOptions
    );
    return await handleResponse(response);
  }

  async function uploadBase64(
    masterId: string,
    worklogId: string,
    fileName: string,
    filesBase64: string
  ) {
    const contentType = { "Content-Type": "application/json" };
    const payload = {
      name: fileName,
      data: filesBase64,
    };
    const requestOptions = {
      method: "POST",
      headers: { ...contentType, ...authHeader() },
      body: JSON.stringify(payload),
    };
    const response = await fetch(
      `${config.api.base}/fileAttachment/base64/${masterId}/${worklogId}`,
      requestOptions
    );
    return (await handleResponse(response)) as { fileId: string };
  }

  return { getFileIds, upload, uploadBase64, deleteFile };
};
