var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',{staticClass:"white"},[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm8":""}},[_c('v-layout',{attrs:{"align-center":"","fill-height":""}},[_c('div',[(!(_vm.isNew || _vm.editVin))?_c('div',{staticClass:"subheading"},[_vm._v(" VIN: "+_vm._s(_vm.model.vin)+" ")]):_c('v-text-field',{ref:"VinTextField",attrs:{"label":"VIN*","required":"","rules":[
                  (v) => !!v || 'VIN is required',
                  (v) => /\w{17}/i.test(v) || 'VIN is not valid',
                ]},model:{value:(_vm.model.vin),callback:function ($$v) {_vm.$set(_vm.model, "vin", $$v)},expression:"model.vin"}})],1),_c('div',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isNew),expression:"!isNew"}],attrs:{"text":"","icon":"","small":"","color":"blue lighten-2"},on:{"click":_vm.onEditVin}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-square-edit-outline")])],1)],1)])],1),_c('v-flex',{attrs:{"xs12":"","sm4":""}},[_c('v-layout',{attrs:{"align-center":"","fill-height":""}},[_c('div',[(!(_vm.isNew || _vm.editStockNumber))?_c('div',{staticClass:"subheading"},[_vm._v(" Stock No.: "+_vm._s(_vm.model.stockNumber)+" ")]):_c('v-text-field',{ref:"StockNumberTextField",attrs:{"label":"Stock Number","required":"","rules":[(v) => !!v || 'Required']},model:{value:(_vm.model.stockNumber),callback:function ($$v) {_vm.$set(_vm.model, "stockNumber", $$v)},expression:"model.stockNumber"}})],1),_c('div',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isNew),expression:"!isNew"}],attrs:{"text":"","icon":"","small":"","color":"blue lighten-2"},on:{"click":_vm.onEditStockNumber}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-square-edit-outline")])],1)],1)])],1),_c('v-flex',{attrs:{"xs12":"","sm4":""}},[_c('v-text-field',{attrs:{"label":"Make*","required":"","rules":[(v) => !!v || 'Required']},model:{value:(_vm.model.make),callback:function ($$v) {_vm.$set(_vm.model, "make", $$v)},expression:"model.make"}})],1),_c('v-flex',{attrs:{"xs12":"","sm4":""}},[_c('v-text-field',{attrs:{"label":"Model*","required":"","rules":[(v) => !!v || 'Required']},model:{value:(_vm.model.model),callback:function ($$v) {_vm.$set(_vm.model, "model", $$v)},expression:"model.model"}})],1),_c('v-flex',{attrs:{"xs12":"","sm4":""}},[_c('v-text-field',{attrs:{"label":"Model Year*","required":"","rules":[
              (v) => !!v || 'Required',
              (v) => /\d{4}/.test(v) || 'Year is not valid (ex. 2018)',
            ]},model:{value:(_vm.model.year),callback:function ($$v) {_vm.$set(_vm.model, "year", $$v)},expression:"model.year"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":""}},[_c('v-text-field',{attrs:{"label":"Color*","required":"","rules":[(v) => !!v || 'Required']},model:{value:(_vm.model.color),callback:function ($$v) {_vm.$set(_vm.model, "color", $$v)},expression:"model.color"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":""}},[_c('v-text-field',{attrs:{"label":"Miles*","required":"","rules":[
              (v) => !!v || 'Required',
              (v) => /[0-9\.]+/.test(v) || 'Miles is not valid (number)',
            ]},model:{value:(_vm.model.miles),callback:function ($$v) {_vm.$set(_vm.model, "miles", $$v)},expression:"model.miles"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":""}},[_c('v-select',{attrs:{"items":_vm.clientsData,"item-text":"name","item-value":"id","label":"Client","rules":[(v) => !!v || 'Required'],"required":"","clearable":""},on:{"click:clear":function($event){_vm.model.clientId = null}},model:{value:(_vm.model.clientId),callback:function ($$v) {_vm.$set(_vm.model, "clientId", $$v)},expression:"model.clientId"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":""}},[_c('date-picker',{attrs:{"label":"Service In","rules":[(v) => !!v || 'Required']},model:{value:(_vm.model.serviceInDate),callback:function ($$v) {_vm.$set(_vm.model, "serviceInDate", $$v)},expression:"model.serviceInDate"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":""}},[_c('date-picker',{attrs:{"label":"Detail in"},model:{value:(_vm.model.detailInDate),callback:function ($$v) {_vm.$set(_vm.model, "detailInDate", $$v)},expression:"model.detailInDate"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"label":"Comments"},model:{value:(_vm.model.comments),callback:function ($$v) {_vm.$set(_vm.model, "comments", $$v)},expression:"model.comments"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('small',[_vm._v("*indicates required field")])])],1)],1),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"ma-2 font-italic grey--text"},[_vm._v(" "+_vm._s(_vm.readonly ? "This Item is archived. Modification not allowed." : "")+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.loading,"disabled":_vm.readonly || _vm.loading},on:{"click":function($event){return _vm.save()}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',[_vm._v("Saving...")])]},proxy:true}])},[_vm._v(" Save ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }