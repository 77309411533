
import { defineComponent, ref, watch } from "vue";
import { useToast } from "@/composables/useToast";

export default defineComponent({
  name: "ToastComponent",
  setup() {
    const { getters, clear } = useToast();
    const show = ref(false);
    const color = ref("accent");
    const text = ref("");

    watch(
      () => getters.message.value,
      async (value) => {
        const message = value;
        if (message !== "") {
          color.value = getters.color.value;
          show.value = true;
          text.value = message;
          clear();
        }
      }
    );
    return {
      show,
      color,
      text,
    };
  },
});
