<template>
  <v-main>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-form v-on:submit.prevent="handleSubmit" id="login-form">
            <v-card class="elevation-12">
              <v-toolbar color="blue darken-3" dark>
                <v-toolbar-title>Login form</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <v-text-field
                  v-model="username"
                  prepend-icon="mdi-email"
                  name="login"
                  label="Login"
                  type="text"
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  id="password"
                  prepend-icon="mdi-lock"
                  name="password"
                  label="Password"
                  type="password"
                ></v-text-field>
                <div class="d-flex justify-end">
                  <v-btn
                    color="primary"
                    type="submit"
                    :disabled="loggingIn"
                    @click="handleSubmit"
                    form="login-form"
                    >Login</v-btn
                  >
                </div>
              </v-card-text>
            </v-card>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data: () => ({
    username: "",
    password: "",
    submitted: false,
  }),
  props: {
    source: String,
  },
  computed: {
    loggingIn() {
      return this.$store.state.authentication.status.loggingIn;
    },
  },
  created() {
    const { dispatch } = this.$store;
    dispatch("authentication/logout");
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.submitted = true;
      const { username, password } = this;
      const { dispatch } = this.$store;
      if (username && password) {
        dispatch("authentication/login", { username, password });
      }
    },
  },
};
</script>
