import { config } from "@/config";

export const authenticationService = {
  login,
  logout,
  activate,
  authHeader,
  handleResponse,
  handleFileDownloadResponse,
};

async function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };

  let response = await fetch(
    `${config.api.base}/appuser/authenticate`,
    requestOptions
  );
  let user = await handleResponse(response);
  // login successful if there's a jwt token in the response
  if (user.token) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem("user", JSON.stringify(user));
  }
  return user;
}

async function activate(token, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(password),
  };
  let response = await fetch(
    `${config.api.base}/appuser/activate/${token}`,
    requestOptions
  );
  return await handleResponse(response);
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}

function authHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem("user"));

  if (user && user.token) {
    return { Authorization: "Bearer " + user.token };
  } else {
    return undefined;
  }
}

async function handleResponse(response) {
  let text = await response.text();
  const data = text && JSON.parse(text);
  if (!response.ok) {
    if (response.status === 401) {
      logout();
      location.reload(true);
    }
    const error = (data && data.message) || response.statusText;
    // eslint-disable-next-line no-console
    console.error(error);
    throw new Error(error);
  }
  return data;
}

async function handleFileDownloadResponse(response) {
  if (!response.ok) {
    if (response.status === 401) {
      logout();
      location.reload(true);
    }
    const error = response.statusText;
    throw new Error(error);
  }
  let blob = await response.blob();
  let disposition = response.headers.get("Content-Disposition");
  let matches = disposition.match(/filename=(.*);/) || [""];
  let filename = matches != null && matches[1] ? matches[1] : "unknown-file";
  return { blob, filename };
}

/*

function login (email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    return fetch(`${config.api.base}/appuser/authenticate`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }

            return user;
        });
}

*/
