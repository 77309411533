import { ref } from "vue";

export function useFilter<T>() {
  const state = ref<T[]>([]);
  const action = (value: T) => {
    if (!state.value || state.value.length === 0) {
      return true;
    } else {
      return state.value.some((x) => x === value);
    }
  };

  return {
    state,
    action,
  };
}
