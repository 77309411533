<template>
  <v-flex lg12 class="white elevation-1">
    <v-list two-line>
      <template v-for="(item, index) in items">
        <v-list-item :key="item.id" ripple @click="onClickRow(item)">
          <v-list-item-content>
            <v-list-item-title :class="{ 'primary--text': !item.archived }">
              {{ vehicle(item) }}
            </v-list-item-title>
            <v-list-item-subtitle
              :class="{ 'primary--text': !item.archived }"
              >{{
                `VIN: ${item.vin} Stock: ${item.stockNumber}`
              }}</v-list-item-subtitle
            >
            <v-list-item-subtitle
              >{{ dateUS(item.serviceInDate)
              }}<span class="font-italic">{{ archiveText(item) }} </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="index + 1 < items.length" :key="index"></v-divider>
      </template>
    </v-list>
  </v-flex>
</template>

<script>
import { dateUS } from "@/filters/index";

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    dateUS,
    vehicle(item) {
      return `${item.make} ${item.model} ${item.year}`;
    },
    onClickRow(item) {
      this.$emit("onClickRow", item.id);
    },
    archiveText(item) {
      return item.archived ? ` *Item archived.` : "";
    },
  },
};
</script>
