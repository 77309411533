var pad = function (n, width, z) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

export const dateZ = {
  notValid: "^Fecha no válida (MM/dd/aaaa ej: 12/31/2000)",
  min: new Date(1900, 0, 1),
  max: new Date(2099, 11, 31),
  add: function (date, val, type) {
    if (type === "days" || !type) {
      let d1 = new Date(date.valueOf());
      d1.setDate(d1.getDate() + val);
      return d1;
    }
    if (type === "years") {
      let d1 = new Date(date.valueOf());
      d1.setFullYear(d1.getFullYear() + val);
      return d1;
    }
    return null;
  },
  today: function (offset = 0) {
    var now = new Date();
    now.setDate(now.getDate() + offset);
    return this.truncate(now);
  },
  tomorrow: function () {
    return this.today(1);
  },
  truncate: function (date) {
    date.setHours(0, 0, 0, 0);
    return new Date(date.valueOf());
  },
  sundayOfWeek(offset = 0) {
    let d = this.today();
    let day = d.getDay();
    return new Date(
      d.getFullYear(),
      d.getMonth(),
      d.getDate() + (day == 0 ? 0 : 7) - day + offset * 7
    );
  },
  firstDayOfMonth(offset = 0) {
    let d = this.today();
    return new Date(d.getFullYear(), d.getMonth() + offset, 1);
  },
  lastDayOfMonth(offset = 0) {
    let d = this.today();
    return new Date(d.getFullYear(), d.getMonth() + 1 + offset, 0);
  },
  parseISO: function (str) {
    //'2019-05-23T09:27:08.14';
    var match = str.match(
      /(\d{4})-(\d{2})-(\d{2})[\sT]?(\d{2}):(\d{2}):?(\d{2})?/
    );
    var year = match[1];
    var month = match[2];
    var day = match[3];
    var hours = match[4];
    var minutes = match[5];
    var seconds = match[6] || 0;
    var dt = new Date(year, month - 1, day, hours, minutes, seconds);
    return dt;
  },
  formatES: function (vdate) {
    var monthNames = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];

    if (!(vdate instanceof Date)) {
      return "";
    }
    var day = vdate.getDate();
    var monthIndex = vdate.getMonth();
    var year = vdate.getFullYear();

    return "" + day + "-" + monthNames[monthIndex] + "-" + year;
  },
  formatUS: function (vdate) {
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    if (!(vdate instanceof Date)) {
      return "";
    }
    var day = vdate.getDate();
    var monthIndex = vdate.getMonth();
    var year = vdate.getFullYear();

    return "" + monthNames[monthIndex] + " " + day + "," + year;
    // return "" + day + "-" + monthNames[monthIndex] + "-" + year;
  },
  formatISO: function (dt) {
    if (!(dt instanceof Date)) {
      return "";
    }
    return this.format(dt, "yyyy-MM-ddTHH:mm:ss");
  },
  format: function (dt, format) {
    if (!(dt instanceof Date)) {
      return "";
    }
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var Days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    format = format.replace("ss", pad(dt.getSeconds(), 2));
    format = format.replace("s", dt.getSeconds());
    format = format.replace("dd", pad(dt.getDate(), 2));
    format = format.replace("d", dt.getDate());
    format = format.replace("mm", pad(dt.getMinutes(), 2));
    format = format.replace("m", dt.getMinutes());
    format = format.replace("MMMM", monthNames[dt.getMonth()]);
    format = format.replace("MMM", monthNames[dt.getMonth()].substring(0, 3));
    format = format.replace("MM", pad(dt.getMonth() + 1, 2));
    format = format.replace(/M(?![ao])/, dt.getMonth() + 1);
    format = format.replace("DD", Days[dt.getDay()]);
    format = format.replace(/D(?!e)/, Days[dt.getDay()].substring(0, 3));
    format = format.replace("yyyy", dt.getFullYear());
    format = format.replace("YYYY", dt.getFullYear());
    format = format.replace("yy", (dt.getFullYear() + "").substring(2));
    format = format.replace("YY", (dt.getFullYear() + "").substring(2));
    format = format.replace("HH", pad(dt.getHours(), 2));
    format = format.replace("H", dt.getHours());
    return format;
  },
};
