export const listHelper = {
  mapStockItems,
  mapServiceItems,
  mapServiceItemsForEmployees,
  vehicle: vehicleTitle,
};

function vehicleTitle(item) {
  return `${item.make} ${item.model} ${item.year}`;
}

function serviceTag(item) {
  return `${item.billed ? "B" : ""} ${item.inPayroll ? "P" : ""}`;
}

function mapStockItems(items) {
  return items.map((x) => ({
    id: x.id,
    vin: x.vin,
    dispo: x.dispo,
    vehicle: vehicleTitle(x),
    stockNumber: x.stockNumber,
    serviceInDate: x.serviceInDate,
    detailInDate: x.detailInDate,
    age: x.age,
    clientCode: x.clientCode,
    workLogCodes: x.workLogCodes,
  }));
}

function mapServiceItems(items) {
  return items.map((x) => ({
    id: x.id,
    vin: x.vin,
    description: x.description,
    note: x.note,
    name: x.name,
    quantity: x.quantity,
    tag: serviceTag(x),
    stockNumber: x.stockNumber,
    assignedToName: x.assignedToName,
    upChargeDate: x.upChargeDate,
    clientCode: x.clientCode,
    billed: x.billed,
    inPayroll: x.inPayroll,
    stockId: x.stockId,
    confirmedByUserAssigned: x.confirmedByUserAssigned,
    fileAttachmentCount: x.fileAttachmentCount,
  }));
}

function mapServiceItemsForEmployees(items) {
  return items.map((x) => ({
    id: x.id,
    vin: x.vin,
    description: x.description,
    fileAttachmentCount: x.fileAttachmentCount,
    note: x.note,
    name: x.name,
    quantity: x.quantity,
    stockNumber: x.stockNumber,
    upChargeDate: x.upChargeDate,
    upChargeCost: x.upChargeCost,
    confirmedByUserAssigned: x.confirmedByUserAssigned,
  }));
}
