
import { defineComponent } from "vue";
import { currency } from "@/helpers/index";

export default defineComponent({
  name: "CurrencyLabel",
  props: {
    value: { type: Number, default: 0 },
    strong: { type: Boolean, default: false },
  },
  setup() {
    return { currency };
  },
});
