const development = process.env.NODE_ENV === "development";
const developmentUrl = process.env.VUE_APP_DEV_URL;

const api = {
  base: "/api",
};

if (development) {
  api.base = developmentUrl + "/api";
}

const config = {
  api: api,
  debug: development,
};

export { config };
