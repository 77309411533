import { useNetwork } from "@vueuse/core";
import { pictureService } from "@/services/picture.service";

export const usePictures = () => {
  const { isOnline } = useNetwork();

  const { uploadBase64, deleteFile: deletePictureFile } = pictureService();

  const postPicture = async (
    payload:
      | {
          masterId: string;
          worklogId: string;
          data: string;
          filename: string;
        }
      | undefined
  ) => {
    if (payload) {
      return await uploadBase64(
        payload.masterId,
        payload.worklogId,
        payload.filename,
        payload.data
      );
    }
  };

  const savePicture = async (
    masterId: string,
    worklogId: string,
    data: string,
    filename = "picture.jpg"
  ) => {
    const pixels = isSafari() ? 600 : 1024;
    const resizedData = await resizePicture(data, pixels, pixels);
    const payload = {
      masterId: masterId,
      worklogId: worklogId,
      data: resizedData,
      filename: filename,
    };
    if (isOnline.value) {
      return await postPicture(payload);
    } else {
      throw new Error("No internet connection");
    }
  };

  const rotatePicture = (base64data: string) => {
    return new Promise<string>((resolve) => {
      const canvas = document.createElement("canvas");
      const image = new Image();
      image.src = base64data;
      image.onload = function () {
        const ctx = canvas.getContext("2d");
        canvas.width = image.height;
        canvas.height = image.width;
        ctx?.rotate((90 * Math.PI) / 180);
        ctx?.translate(0, -canvas.width);
        ctx?.drawImage(image, 0, 0);
        resolve(canvas.toDataURL("image/png"));
      };
    });
  };

  const isSafari = () =>
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const resizePicture = (
    base64Str: string,
    maxWidth = 600,
    maxHeight = 600
  ) => {
    return new Promise<string>((resolve) => {
      const img = new Image();
      img.src = base64Str;
      img.onerror = () => {
        URL.revokeObjectURL(img.src);
        // Handle the failure properly
        console.log("Cannot resize image");
        resolve(base64Str);
      };
      img.onload = () => {
        URL.revokeObjectURL(img.src);
        const canvas = document.createElement("canvas");
        const MAX_WIDTH = maxWidth;
        const MAX_HEIGHT = maxHeight;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx?.drawImage(img, 0, 0, width, height);
        if (isSafari()) {
          resolve(canvas.toDataURL("image/png", 0.7));
        } else {
          resolve(canvas.toDataURL("image/webp", 0.7));
        }
      };
    });
  };

  const convertBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const savePictureFile = async (
    serviceId: string,
    worklogId: string,
    fileData: File
  ) => {
    const data = (await convertBase64(fileData)) as string;
    if (data) {
      const pictureId = await savePicture(serviceId, worklogId, data);
      return pictureId;
    }
    return undefined;
  };

  return { savePicture, savePictureFile, rotatePicture, deletePictureFile };
};
