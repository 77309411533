<template>
  <div :title="title">
    <v-icon small :color="color">mdi-checkbox-blank-circle</v-icon>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    color() {
      return this.value ? "green" : "red";
    },
    title() {
      return this.value ? "Active" : "Deactivated";
    },
  },
};
</script>

<style></style>
