import { useDebounceFn } from "@vueuse/shared";
import { ref, customRef } from "vue";

const useRefDebounce = <T>(initialValue: T, delay: number) => {
  const state = ref(initialValue);
  const debouncedRef = customRef((track, trigger) => ({
    get() {
      track();
      return state.value;
    },
    set: useDebounceFn((value) => {
      state.value = value;
      trigger();
    }, delay),
  }));
  return debouncedRef;
};

export default useRefDebounce;
