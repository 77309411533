
import { defineComponent, ref, computed, watch } from "vue";
import { isEmpty, dateZ } from "@/helpers";
import { reportService } from "@/services";
import { useToast } from "@/composables/useToast";
import { onMounted } from "vue";
import DataExplorer from "@/components/workspace/layout/data-explorer.vue";
import store from "@/store/index";

export default defineComponent({
  components: { DataExplorer },
  setup() {
    const { error } = useToast();

    const filterDateRange = computed(
      () => store.getters["context/filterDateRangeForServer"]
    );

    const loading = ref(false);
    const items = ref([]);

    const headers = [
      { text: "Vin", value: "vin" },
      { text: "Year", value: "year" },
      { text: "Make", value: "make" },
      { text: "Model", value: "model" },
      { text: "Color", value: "color" },
      { text: "Stock", value: "stockNumber" },
      { text: "In Service (days)", value: "daysInService" },
      { text: "Client", value: "clientCode" },
    ];

    const empty = computed(() => isEmpty(items.value) && !loading.value);

    const init = async () => {
      try {
        loading.value = true;
        items.value = await reportService.get("detail", "");
      } catch (err) {
        error(err as string);
      } finally {
        loading.value = false;
      }
    };

    const fileLink = ref<HTMLAnchorElement>();

    const download = (data: { blob: Blob; filename: string }) => {
      const a = fileLink.value as HTMLAnchorElement;
      const url = window.URL.createObjectURL(data.blob);
      a.href = url;
      a.download = data.filename;
      a.click();
    };

    const csv = async () => {
      loading.value = true;
      try {
        let data = await reportService.csv("detail", "");
        download(data);
      } catch (err) {
        error(err as string);
      }
      loading.value = false;
    };

    const refresh = init;

    watch(filterDateRange, refresh);

    onMounted(init);

    return { loading, items, headers, empty, dateZ, fileLink, csv };
  },
});
