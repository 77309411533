import { config } from "@/config";
import { authenticationService } from "./authentication.service";
let { authHeader, handleResponse } = authenticationService;

export const dashboardService = {
  get,
};

async function get(range) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(
    `${config.api.base}/dashboard/${range}`,
    requestOptions
  );
  return await handleResponse(response);
}
