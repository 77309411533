export function currency(
  value: number | string | null | undefined,
  zero = false
) {
  if (!value) {
    if (zero) return "$0.00";
    else return "";
  }
  return (
    "$" +
    parseFloat(value.toString())
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
  );
  //return "$" + parseFloat(val).toFixed(2);
}
/*
function roundToTwoDecimals(value: string | number): number {
  const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
  if (isNaN(parsedValue)) {
      throw new Error('Invalid input: value must be a valid number or a string representation of a number.');
  }
  return Math.round(parsedValue * 100) / 100;
}
*/
