<template>
  <v-app-bar color="blue darken-3" dense fixed clipped-left dark app>
    <v-app-bar-nav-icon
      @click.stop="drawerInternal = !drawerInternal"
    ></v-app-bar-nav-icon>
    <v-toolbar-title>
      <span class="hidden-sm-and-up font-weight-bold" :title="BranchName">
        {{ BranchCode }}
      </span>
      <span class="hidden-xs-only font-weight-bold">{{ BranchName }}</span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn v-if="$role.isManager" text @click="vin()">
      <v-icon>mdi-barcode-scan</v-icon>
    </v-btn>
    <v-btn icon @click="dashboard()">
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>
    <v-btn icon @click="logout()">
      <v-icon>mdi-exit-to-app</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    drawerInternal: {
      get() {
        return this.drawer;
      },
      set(v) {
        this.$emit("update:drawer", v);
      },
    },
    ...mapGetters("authentication", ["BranchCode", "BranchName"]),
  },
  methods: {
    logout() {
      this.$router.push({ name: "login" });
    },
    dashboard() {
      this.$router.push({ name: "w.dashboard" });
    },
    vin() {
      this.$router.push({ name: "w.search.vin" });
    },
  },
};
</script>

<style></style>
