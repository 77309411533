<template>
  <v-dialog v-model="dialog" max-width="300">
    <v-card>
      <v-card-title> </v-card-title>
      <v-card-text>
        <div class="title">
          {{ title }}
        </div>
        {{ text }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancel()"> Cancel </v-btn>
        <v-btn color="primary" text @click="ok()"> Ok </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      title: "",
      text: "",
    };
  },
  watch: {
    dialog: {
      handler(val) {
        if (!val) {
          this.cancel();
        }
      },
    },
  },
  methods: {
    show(title, text) {
      this.title = title || "Confirm!!";
      this.text = text || "";
      return new Promise((resolve) => {
        this.resolve = resolve;
        this.dialog = true;
      });
    },
    isShow() {
      return this.dialog;
    },
    ok() {
      this.dialog = false;
      this.resolve && this.resolve(true);
    },
    cancel() {
      if (this.dialog) {
        this.dialog = false;
        this.resolve && this.resolve(false);
      }
    },
  },
};
</script>

<style></style>
