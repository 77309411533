
import { defineComponent, ref } from "vue";
import { reportService } from "@/services";
import { isEmpty } from "@/helpers";
import { useToast } from "@/composables/useToast";

export default defineComponent({
  name: "payroll-export-btn",
  props: {
    selectedItemIds: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  setup(props) {
    const { error } = useToast();

    const loading = ref(false);

    const fileLink = ref<HTMLAnchorElement>();

    const download = (data: { blob: Blob; filename: string }) => {
      const a = fileLink.value as HTMLAnchorElement;
      const url = window.URL.createObjectURL(data.blob);
      a.href = url;
      a.download = data.filename;
      a.click();
    };

    const excelPayroll = async () => {
      if (isEmpty(props.selectedItemIds)) {
        error("Please select items to export");
        return;
      }
      loading.value = true;
      try {
        const data = await reportService.fileDownload(
          "payroll/excel",
          props.selectedItemIds
        );
        download(data);
      } catch (err) {
        error(err as string);
      }
      loading.value = false;
    };
    return { excelPayroll, fileLink, loading };
  },
});
